const Api = {
    ADDRESS_FIND_ZIPCODE: "/api/address/getAddrByZipCode/",
    OTP_CREATE: "/api/otp/create",
    OTP_VERIFY: "/api/otp/verify",
    CUSTOMER_CREATE : "/api/customer/register",
    CUSTOMER : "/api/customer/",
    CUSTOMER_CHECK_BY_LINE : "/api/customer/check/line-uid/",
    CUSTOMER_CHECK_BY_MOBILE : "/api/customer/check/mobile-no/",
    CUSTOMER_PROFILE_BY_LINE : "/api/customer/search/by-line/",
    CUSTOMER_PROFILE_BY_ID : "/api/customer/search/by-id/",
    CUSTOMER_PROFILE_BY_MOBILE : "/api/customer/search/by-mobile-no/",
    CUSTOMER_CHANGE_PASS : "/api/customer/change/password/",
    SCORE_HISTORY_LIST : "/api/score/history/list/by-customer",
    SCORE_HISTORY_ERAN_LIST : "/api/score/history/list/earn/by-customer",
    SCORE_HISTORY_REDEEM_LIST : "/api/score/history/list/redeem/by-customer",
    UTILITY_ADD_USER : "/api/utility/line/add-user/rich-menu",
    PROMOTION_WITH_ACTIVE_PERIOD: "/api/promotion/by-type/promotion",
    NEWARRIVAL_WITH_ACTIVE_PERIOD : "/api/promotion/by-type/new_alive",
    
    PRODUCT_BY_BRANCH_CODE : "/api/product/search/list/by-branch",
    BRANCH_LIST : "/api/branch",
    CATEGORY_LIST : "/api/categories/dropdown",
    PRODUCT_BRANCH_ID_BY_CATE : "/api/product/search/list/branch-id/by-cate",
}
export default Api;
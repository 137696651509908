import React from 'react';
import {axios_json} from "../axios";
import Api from "../constants/api";
import { authHeader, authBasicHeader } from "./auth-header";
import moment from "moment";



const getProductByBranchAndCate = (obj) => {

    return axios_json.get(`${Api.PRODUCT_BRANCH_ID_BY_CATE}`, {
        headers: authBasicHeader(),
        params: {
            search : obj.search,
            branch_id : obj.branch_id,
            categories : obj.categories,
            page : obj.page,
            skip : obj.skip
        }
    })
};

const categoriesList = () => {

    return axios_json.get(`${Api.CATEGORY_LIST}`, {
        headers: authBasicHeader()
    })
};

const branchList = () => {

    return axios_json.get(`${Api.BRANCH_LIST}`, {
        headers: authBasicHeader()
    })
};

// eslint-disable-next-line import/no-anonymous-default-export
export default  {
    getProductByBranchAndCate,
    categoriesList,
    branchList
};
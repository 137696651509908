import React, { useEffect, useState, useContext } from "react";
import {
  Flex,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Carousel,
  Typography,
  Skeleton,
  Image,
} from "antd";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import BadgeMini from "../../components/badgeMini";
import product from "../../components/product";
import Product from "../../components/product";
import { formatNumberZero } from "../../services/function";
import responseHandleSrv from "../../services/responseHandleSrv";
import promotionSrv from "../../services/promotionSrv";
import productSrv from "../../services/productSrv";
import { HeaderContext } from "../../context/headerContex";

const ProductHome = () => {
  const { Title } = Typography;
  const [preload, setPreload] = useState(false);
  const [promotion, setPromotion] = useState([]);
  const [products, setProduct] = useState([]);
  const { setHeaderMenu } = useContext(HeaderContext);

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  useEffect(() => {
    setHeaderMenu("promotion");
    setPreload(true);
    getPromotion();
    getProduct();
  }, []);

  const getPromotion = () => {
    (async () => {
      await promotionSrv
        .getWithActivePeriod(data)
        .then((response) => {
          // console.log('getPromotion', response.data);
          if (response.data.success) {
            setPromotion(response.data.data);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getProduct = () => {
    let obj = {
      search: "",
      branch_id: 8,
      page: 1,
      skip: 4,
      categories: "home",
    };
    (async () => {
      await productSrv
        .getProductByBranchAndCate(obj)
        .then((response) => {
          // console.log('getProduct', response.data);
          if (response.data.success) {
            setProduct(response.data.data.result);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  let data =
    sessionStorage.getItem("customer_profile") === null
      ? ""
      : JSON.parse(sessionStorage.customer_profile);
  console.log("data", data);
  data = "";

  const imgCss = {
    width: "380px",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
  };
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} style={{ textAlign: "center" }}>
          <Title level={4}></Title>
        </Col>
        {data != "" && (
          <Col span={22} offset={1}>
            <Card>
              <List>
                <List.Item
                  extra={<RightOutlined style={{ fontSize: "18px" }} />}
                >
                  <List.Item.Meta
                    avatar={
                      data != "" && data.picture_url ? (
                        <Avatar src={data.picture_url} />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )
                    }
                    title={
                      data != "" ? data.firstname + " " + data.lastname : ""
                    }
                    description={
                      <>
                        <Row gutter={[5, 0]}>
                          <Col>
                            <BadgeMini
                              type="stamp"
                              point={formatNumberZero(data.stamp, 0)}
                            />
                          </Col>
                          <Col>
                            <BadgeMini
                              type="point"
                              point={formatNumberZero(data.point, 0)}
                            />
                          </Col>
                          <Col>
                            <BadgeMini
                              type="coin"
                              point={formatNumberZero(data.coin, 0)}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </List.Item>
              </List>
            </Card>
          </Col>
        )}
        {/* <Col span={22} offset={1}>
          <Flex justify={"space-between"}>
            <div>
              <Title level={5} className={"title-h5"}>
                โปรโมชั่น
              </Title>
            </div>
            <div>
              <RightOutlined style={{ fontSize: "18px" }} />
            </div>
          </Flex>
        </Col> */}
        <Col
          span={22}
          offset={1}
          style={{
            justifyContent: "center",
            justifyItems: "center",
            textAlign: "center",
          }}
        >
          <Carousel autoplay style={{ width: "380px", margin: "0 auto" }}>
            {promotion &&
              promotion.map((pro) => (
                <div>
                  <Image src={pro.banner} style={{ ...imgCss }} />
                </div>
              ))}
          </Carousel>
        </Col>
        {/* <Col span={22} offset={1}>
          <Flex justify={"space-between"}>
            <div>
              <Title level={5} className={"title-h5"}>
                ของรางวัล
              </Title>
            </div>
            <div>
              <RightOutlined style={{ fontSize: "18px" }} />
            </div>
          </Flex>
        </Col>
        <Col span={22} offset={1}>
          <Row gutter={[5, 5]}>
            {products && products.map((p) => (
              <Col span={12}>
                <Product
                  name={p.name_th}
                  type={'point'}
                  point={formatNumberZero(p.redeem_point)}
                  img={p.thumb_pic}
                />
              </Col>
            ))}
          </Row>
        </Col> */}
      </Row>
    </>
  );
};
export default ProductHome;

import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Card, Button, Image, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout, getCurrentUser } from "../../services/auth";
import icoUser from "../../assets/images/ico-user.png";
import icoUserX from "../../assets/images/ico-user-x.png";
import { HeaderContext } from "../../context/headerContex";

const Setting = () => {
  const { Title } = Typography;
  let user = getCurrentUser();
  const { setHeaderMenu } = useContext(HeaderContext);
  useEffect(() => {
    setHeaderMenu("setting");
  }, []);
  return (
    <>
      <Row gutter={[10, 10]} style={{ alignContent: "center" }}>
        <Col span={22} offset={1} style={{ textAlign: "left" }}>
          <Title level={3}>ตั้งค่า</Title>
        </Col>
        <Col span={22} pull={1} offset={2}>
          <Card bodyStyle={{ padding: "8px 0px" }}>
            <Link to="/profile/edit">
              <Row
                type="flex"
                style={{ alignItems: "center", margin: "0px 20px" }}
              >
                <Col span={20}>
                  <Title level={4}>
                    <Image
                      src={icoUser}
                      sizes="18"
                      style={{
                        paddingRight: "10px",
                        top: "-3px",
                        position: "relative",
                      }}
                    />
                    แก้ไขข้อมูลส่วนตัว
                  </Title>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <RightOutlined
                    color="primary"
                    style={{
                      fontSize: "20px",
                      verticalAlign: "top",
                      color: "#acacac",
                    }}
                  />
                </Col>
              </Row>
            </Link>
            <Divider />
            <Link to="/profile/changepassword">
              <Row
                type="flex"
                style={{ alignItems: "center", margin: "0px 20px" }}
              >
                <Col span={20}>
                  <Title level={4}>
                    <Image
                      src={icoUser}
                      sizes="18"
                      style={{
                        paddingRight: "10px",
                        top: "-3px",
                        position: "relative",
                      }}
                    />
                    เปลี่ยนรหัสผ่าน
                  </Title>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <RightOutlined
                    color="primary"
                    style={{
                      fontSize: "20px",
                      verticalAlign: "top",
                      color: "#acacac",
                    }}
                  />
                </Col>
              </Row>
            </Link>
            {user && (
              <>
                <Divider />

                <Row
                  type="flex"
                  style={{ alignItems: "center", margin: "0px 20px" }}
                >
                  <Col span={20} onClick={logout}>
                    <Title level={4}>
                      <Image
                        src={icoUserX}
                        sizes="18"
                        style={{
                          paddingRight: "10px",
                          top: "-3px",
                          position: "relative",
                        }}
                      />
                      ออกจากระบบ
                    </Title>
                  </Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    <RightOutlined
                      color="primary"
                      style={{
                        fontSize: "20px",
                        verticalAlign: "top",
                        color: "#acacac",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Setting;

import React, { createContext, useState } from "react";
const PreloadContext = createContext({});
const PreloadContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  return (
    <PreloadContext.Provider value={{loading,setLoading}}>
      {children}
    </PreloadContext.Provider>
  );
};
export { PreloadContext, PreloadContextProvider };

import React from "react";
import { Card, Row, Col, Typography, Image, Flex } from "antd";
import stamp from "../assets/images/stamp.png";
import point from "../assets/images/point.png";
import coin from "../assets/images/coin.png";

export default function BadgeMini(props) {
  const { Text } = Typography;
  let imgSrc = stamp;
  const type = props.type;
  switch (type) {
    case "stamp":
      imgSrc = stamp;
      break;
    case "point":
      imgSrc = point;
      break;
    case "coin":
      imgSrc = coin;
      break;
    default:
      imgSrc = stamp;
      break;
  }
  return (
    <>
      <Flex gap="small" vertical={false}>
        <Image src={imgSrc} />
        <Text type="secondary">{props.point}</Text>
      </Flex>
    </>
  );
}

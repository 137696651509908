import React from 'react'
import { Result, Button, Layout } from 'antd';
import { SmileOutlined } from '@ant-design/icons';




const Success = ({ nextStep, prevStep, values }) => {
    console.log("line value",values);
    const { Header, Content, Footer } = Layout;
    // const classes = styleSrv.useStyles();
    const liff = window.liff;
    const backToCustomer = () => {

        window.location.reload();
    }
    const closeLiff = () => {
        liff.closeWindow()
    }
    sessionStorage.is_customer = 1;
    
    // let user = sessionStorage.getItem('user') === null ? '' : JSON.parse(sessionStorage.user);
    return (
        <div className='dfc-centerXYpage'>
            <Result
                status="success"
                title={<b style={{ color: '#52c41a' }} className='df-centerWarp'>ท่านลงทะเบียนเป็นสมาชิก <br />เรียบร้อยแล้ว</b>}
                // subTitle={<span style={{color: '#ff4d4f'}}>*หากต้องการออกจากหน้าจอ กดปุ่ม X เพื่อปิด</span>}
                extra={[
                    <Button size="large" style={{borderColor: '#52c41a', color: '#52c41a',backgroundColor:'white'}}>
                        {/* <div className='df-centerY'><AiOutlineArrowLeft />&nbsp;&nbsp;กลับไปหน้าหลัก</div> */}
                        <div className='df-centerY' onClick={closeLiff}>ตกลง</div>
                    </Button>
                ]}
            />
            {values.id == 0 &&
                <Content style={{ padding: '0 50px', textAlign: 'center' }}>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                        <h1>ยินดีต้อนรับ</h1>
                        <h2>คุณได้รับ Welcome Point</h2>
                        <h1>500 คะแนน <SmileOutlined /></h1>
                        {/* <p>Enjoy your 500 Welcome Points! <SmileOutlined /></p> */}
                    </div>
                </Content>
     
            } 
        </div>
    )


}


export default Success
import React, { useEffect, useState } from "react";
import Product from "./product";
import { Row, Col, Button, List, Flex } from "antd";
import { formatNumberZero } from "../services/function";
import productSrv from "../services/productSrv";
import responseHandleSrv from "../services/responseHandleSrv";

export default function ProductList(props) {
  const { type, tab, start, search, branch_id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [lists, setList] = useState([]);
  const [data, setData] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(start);
  const [total, setTotal] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: start,
    pageSize: 8,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  useEffect(() => {
    // getProduct(pageData.currentPage, pageData.pageSize, search, branch_id, tab);
    if(start === 1){
      setList([]);
    }
    getProduct(pageData.currentPage, pageData.pageSize, search, branch_id, tab);
  }, [type, tab, start, search, branch_id]);

  const getProduct = (page, pageSize, search, branch_id, categories) => {
    let obj = {
      search: search,
      branch_id: branch_id,
      page: page,
      skip: pageSize,
      categories: categories === 'all' ? '' : categories
    };
    console.log('obj', obj);
    (async () => {
      setIsLoading(true);
      await productSrv.getProductByBranchAndCate(obj).then(response => {
        console.log('getProduct', response.data);
        if (response.data.success) {
          let res = response.data.data.result

          if (lists.length === 0) {
            setList(res);
          }
          else {
            let exist = lists.concat(res);
            setList(exist);
          }
          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
        }
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        responseHandleSrv.handleError(err);
      });
    })();
  }
  const onLoadMore = () => {
    setLoading(true);

    let current = pageData.currentPage;
    // console.log('current', current);
    setPageData((val) => ({
      ...val,
      currentPage: current + 1
    }));
    let next = current + 1;
    getProduct(next, pageData.pageSize, search, branch_id, tab);
  };
  // const loadMore =
  //   (pageData.totalcount !== lists.length && !isLoading) ? (
  //     <div
  //       style={{
  //         textAlign: 'center',
  //         marginTop: 12,
  //         height: 32,
  //         lineHeight: '32px',
  //       }}
  //     >
  //       <Button onClick={onLoadMore}>more</Button>
  //     </div>
  //   ) : null;


  return (
    <Row gutter={[0, 20]}>

      {lists && lists.map(
        (product) =>
          // props.type == product.type && (
          <Col span={24}>
            <Product
              name={product.name_th}
              type={'point'}
              point={formatNumberZero(product.redeem_point)}
              img={product.thumb_pic}
            />
          </Col>
        // )
      )}


      <Col span={22} style={{ textAlign: "center" }}>
      {console.log(pageData.totalcount,lists.length,isLoading )}
        {((pageData.totalcount !== lists.length) && !isLoading) ? (
          
          <Button onClick={onLoadMore} style={{ marginTop: '12px' }}>more</Button>
        ) 
        : null
        }
      </Col>

      {/* {(pageData.totalcount !== lists.length && !isLoading) ? (
        <Col span={22} style={{ textAlign: "center" }}>

          <div
            style={{
              textAlign: 'center',
              // marginTop: 12,
              height: 32,
              // lineHeight: '32px',
              backgroundColor: 'red',
              display: 'flex',
              alignItems: 'center'

            }}
          >
            <Button onClick={onLoadMore} style={{marginTop : '12px'}}>more</Button>
          </div>

        </Col>

      ) : null
      } */}

    </Row>

  );
}

import React, { useState } from "react";
import { Steps } from "antd";

const { Step } = Steps;

const steps = [
  {
    title: "ข้อมูล",
  },
  {
    title: "รหัสยืนยัน",
  },
  {
    title: "ลงทะเบียน",
  },
];

export default function StepPage({ step }) {
  const [current, setCurrent] = useState(step);

  return (
    <div>
      <Steps
        current={current}
        labelPlacement="herizontal"
        direction="herizontal"
        size="small"
        responsive={false}
        progressDot={false}
        type="navigation"
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content} </div>
    </div>
  );
}

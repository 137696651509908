import React, { createContext, useState } from "react";
const CreateUserContext = createContext({});
const CreateUserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  return (
    <CreateUserContext.Provider value={{user,setUser,password,setPassword}}>
      {children}
    </CreateUserContext.Provider>
  );
};
export { CreateUserContext, CreateUserContextProvider };

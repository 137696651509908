import React from "react";
import { Navigate } from "react-router-dom";

export default function MiddleHistory() {
  if (sessionStorage.getItem("customer_profile")) {
    return <Navigate to="/profile/history" />;
  } else {
    return <Navigate to="/signin" />;
  }
}

import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Flex,
  Button,
  Typography,
  Input,
  Tooltip,
  Checkbox,
  Modal,
  DatePicker,
  Radio,
  Select,
} from "antd";
import dayjs from "dayjs";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { CreateUserContext } from "../../context/createUser";

export default function Signup() {
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const { user, password } = useContext(CreateUserContext);
  useEffect(() => {
    console.log(`user : ${user}  password : ${password}`);
  }, []);
  return (
    <>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
          <Title level={5}>โปรดระบุรายละเอียด</Title>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              ชื่อ
            </Title>
            <Input placeholder="ชื่อ" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              นามสกุล
            </Title>
            <Input placeholder="นามสกุล" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              อีเมล
            </Title>
            <Input placeholder="อีเมล" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              เบอร์โทรศัพท์
            </Title>
            <Input placeholder="เบอร์โทรศัพท์" />
          </div>
        </Col>

        <Col span={22}>
          <div>
            <Typography.Title level={5} className="title-h5">
              วันเกิด
            </Typography.Title>
            <DatePicker format={dateFormat} style={{ width: "100%" }} />
          </div>
        </Col>

        <Col span={22}>
          <div>
            <Typography.Title level={5} className="title-h5">
              เพศ
            </Typography.Title>
            <Radio.Group>
              <Radio value="man">ชาย</Radio>
              <Radio value="woman">หญิง</Radio>
              <Radio value="other">ไม่ระบุ</Radio>
            </Radio.Group>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              ที่อยู่
            </Title>
            <Input placeholder="ที่อยู่" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              จังหวัด
            </Title>
            <Select placeholder="เลือกจังหวัด" className="width100" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              อำเภอ/เขต
            </Title>
            <Select placeholder="เลือกอำเภอ/เขต" className="width100" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              ตำบล/แขวง
            </Title>
            <Select placeholder="เลือกตำบล/แขวง" className="width100" />
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Title level={5} className="title-h5">
              รหัสไปรษณีย์
            </Title>
            <Input placeholder="รหัสไปรษณีย์" />
          </div>
        </Col>

        <Col span={22} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button type="primary" block>
            ยืนยัน
          </Button>
        </Col>
      </Row>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Spin, Flex, Card } from "antd";
import axios from "axios";
import { PreloadContext } from "../context/preload";
import Preload from "../components/preload";
import { HeaderContext } from "../context/headerContex";


const Policy = () => {
  const [policy, setPolicy] = useState();
  const [show, setShow] = useState(true);
  const { Title } = Typography;
  const { setLoading } = useContext(PreloadContext);
  const { setHeaderMenu } = useContext(HeaderContext);
  
  const headers = {
    Authorization: "Basic " + process.env.REACT_APP_BASIC_HEADER,
    accept: "application/json",
  };
  
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          process.env.REACT_APP_API_PRD + "/api/content/policy",
          { headers }
        );
        setPolicy(data.data.data.description);
      } finally {
        setLoading(false);
      }
    })();
    setHeaderMenu("policy");

  }, []);
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={22} offset={1} style={{ textAlign: "left" }}>
          <Title level={2}>เงื่อนไขกิจกรรมและนโยบายความเป็นส่วนตัว</Title>
        </Col>
      </Row>
      <Preload>
        <Row gutter={[10, 10]}>
          <Col span={22} pull={1} offset={2}>
            <Card bodyStyle={{padding:"0px",borderBlockColor:"#000",border:"1px solid #000", borderRadius:"10px"}}>
              <div id={"div-policy"}
                dangerouslySetInnerHTML={{ __html: policy }}
                style={{ height: "540px", overflowX: "scroll" ,padding:"15px"}}
              />
            </Card>
          </Col>
        </Row>
      </Preload>
    </>
  );
};
export default Policy;

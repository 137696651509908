import React from "react";
import { Navigate } from "react-router-dom";
import Signin from "../pages/register/signin";
import ProfileLine from "../pages/lineForm/profileHome";
import ProfileHome from "../pages/profile/profileHome";

export default function MiddleReward() {
  if (sessionStorage.getItem("line_profile")) {
    return <Navigate to="/line/reward" />;
  } else if (sessionStorage.getItem("user")) {
    return <Navigate to="/product/reward" />;
  } else {
    return <Navigate to="/product/reward" />;
  }
}

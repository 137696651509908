import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {  Image, Carousel } from "antd";
import { PreloadContext } from "../context/preload";
import Preload from "./preload";
import Api from "../constants/api";

const RewardSlide = () => {
  const [rewards, setRewards] = useState([]);
  const { setLoading } = useContext(PreloadContext);
  const uri = `${process.env.REACT_APP_API_PRD}${Api.NEWARRIVAL_WITH_ACTIVE_PERIOD}`;
  console.log("uri", uri);
  const headers = {
    Authorization: "Basic " + process.env.REACT_APP_BASIC_HEADER,
    accept: "application/json",
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(uri, {
          headers,
        });
        setRewards(data.data.data);
      } finally {
        console.log("rewards", rewards);
        setLoading(false);
      }
    })();
  }, []);
  const imgCss = {
    width: "380px",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
  };
  return (
    <Preload>
      <Carousel autoplay={true} style={{ width: "380px", margin: "0 auto" }}>
        {rewards.map((item) => (
          <div>
            <NavLink to={item.link}>
              <Image style={{ ...imgCss }} src={item.banner} preview={false} />
            </NavLink>
          </div>
        ))}
      </Carousel>
    </Preload>
  );
};
export default RewardSlide;

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Flex, Button, Typography } from "antd";

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Input, Tooltip, Checkbox, Modal } from "antd";
import { CreateUserContext } from "../../context/createUser";

export default function User() {
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const { setUser, setPassword } = useContext(CreateUserContext);
  let navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let user = e.target.username.value;
    let password = e.target.password.value;
    setUser(user);
    setPassword(password);
    navigate("/register/signup");
  };
  return (
    <>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
          <Title level={5}>สร้างบัญชี</Title>
        </Col>
      </Row>
      <form onSubmit={handleSubmit} method="post">
        <Row justify="center" gutter={[0, 10]}>
          <Col span={22}>
            <div>
              <Title level={5} className="title-h5">
                ชื่อผู้ใช้
              </Title>
              <Input
                id="username"
                name="username"
                placeholder="Enter your username"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </div>
          </Col>

          <Col span={22}>
            <div>
              <Typography.Title level={5} className="title-h5">
                รหัสผ่าน
              </Typography.Title>
              <Input.Password
                placeholder="Password"
                id="password"
                name="password"
              />
            </div>
          </Col>

          <Col span={22}>
            <div>
              <Typography.Title level={5} className="title-h5">
                ยืนยันรหัสผ่าน
              </Typography.Title>
              <Input.Password placeholder="Confirm password" />
            </div>
          </Col>
          <Col span={22}>
            <Checkbox>
              <span>consent</span>
              <Button
                type="link"
                style={{color : '#1ABC9C'}}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Read more
              </Button>
            </Checkbox>
          </Col>
          <Col span={22} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" block>
              Register
            </Button>
          </Col>
        </Row>
      </form>
      <Modal
        title="Modal 1000px width"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    </>
  );
}

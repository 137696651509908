import React, { useState } from "react";
import { Row, Col, Flex, Button, Typography, Form } from "antd";

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from "../../services/auth-header";
import { Input, Tooltip, Checkbox, Modal } from "antd";
import { toHaveFormValues } from "@testing-library/jest-dom/matchers";

export default function Forgotpassword() {
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);
  const [chkPhone, setChkPhone] = useState(false);
  const [form] = Form.useForm();
  const root = window.location.origin;


  const onFinish = (values) => {

    console.log("values", values);
    sendOtp(values.mobile_no)
    
  };
  const getPhone = (e) => {
    if (e.target.value) {
      if(e.target.value.length == 10)
      {
        console.log('getPhone', e.target.value.length);
        getPhoneCheck(e.target.value);
      }
    }
  };
  const sendOtp = (mobile_no) => {

    let data = { mobile_no: mobile_no };
    (async () => {
       await axios_json.post(`${Api.OTP_CREATE}`, data, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');
          let data = response.data.data
          window.location.href = `${root}/register/verify?mobileNo=${mobile_no}&ref=${data.ref_code}&code=${data.verify_code}`;
        }
      }).catch(err => {
       
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const getPhoneCheck = (phone) => {
    console.log("getPhoneCheck", phone);

    (async () => {
      await axios_json
        .get(`${Api.CUSTOMER_CHECK_BY_MOBILE + phone}`, {
          headers: authBasicHeader(),
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
            if (response.data.data) {
              setChkPhone(true);
            } else {
              responseHandleSrv.handleErrorMsg(
                "เกิดข้อผิดพลาด",
                "เบอร์โทรศัพท์นี้ไม่มีในระบบ"
              );
              setChkPhone(false);
            }
            console.log(response.data.data);
          }
        })
        .catch((err) => {
          setChkPhone(true);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onFinishFailed = () => { };
  return (
    <>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
          <Title level={5}>ลืมรหัสผ่าน</Title>
          <p>
            <Text type="secordary">
              รีเซ็ตรหัสผ่านด้วยเบอร์โทรศัพท์ที่ใช้งาน
            </Text>
          </p>
        </Col>
      </Row>
      <Form
        form={form}
        name="user"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify="center" gutter={[0, 10]}>
          <Col span={22}>
            <div>
              {/* <Title level={5} className="title-h5">
                เบอร์โทรศัพท์
              </Title>
              <Input
                placeholder="เบอร์โทรศัพท์"
                prefix={<UserOutlined className="site-form-item-icon" />}
              /> */}
              <Form.Item
                name="mobile_no"
                label={
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    เบอร์โทรศัพท์
                  </label>
                }
                // onChange={inputChange("mobile_no")}
                rules={[
                  {
                    required: true,
                    message: "เบอร์โทรศัพท์",
                  },
                  {
                    pattern: /^[0][0-9]{9}$\b/,
                    message: "เบอร์โทรศัพท์ไม่ถูกต้อง",
                  },
                ]}
              >
                <Input
                  onChange={getPhone}
                  placeholder="เบอร์โทรศัพท์"
                  maxLength={10}
                />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} style={{ textAlign: "center" }}>
            {/* <Button type="primary" block>
            ยืนยัน
          </Button> */}
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={!chkPhone}
            >
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </Form >
    </>
  );
}

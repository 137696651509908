import axios from "../axios";
import Api from "../constants/api";
import { authHeader } from "./auth-header";
import { notification } from 'antd';
import responseHandleSrv from "./responseHandleSrv";


const setSession = (res) => {
  if (typeof (Storage) !== "undefined") {
    sessionStorage.user = JSON.stringify(res.data.data);
  }
};


const clearSession = () => {

  sessionStorage.removeItem("user")
  sessionStorage.removeItem("customer_profile");
  
};

const logout = () => {

  // let user = sessionStorage.getItem('user') === null ? '' : JSON.parse(sessionStorage.user);
  clearSession();

  const root = window.location.origin;
  window.location.href = root;
};

const getCurrentUser = () => {
  let userSession = sessionStorage.getItem('user') === null ? '' : JSON.parse(sessionStorage.user);
  return userSession;
};

const getCurrentCustomer = () => {
  let custSession = sessionStorage.getItem('customer_profile') === null ? '' : JSON.parse(sessionStorage.customer_profile);
  return custSession;
};

const getLineProfile = () => {
  let line = sessionStorage.getItem('line_profile') === null ? '' : JSON.parse(sessionStorage.line_profile);
  return line;
};

export  {
  logout,
  getCurrentUser,
  clearSession,
  setSession,
  getCurrentCustomer,
  getLineProfile
};
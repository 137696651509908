import React, { useEffect, useState, useContext } from "react";
import {
  Flex,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Carousel,
  Typography,
  Input,
  Space,
  Tabs,Form, Select
} from "antd";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import BadgeMini from "../../components/badgeMini";
import product from "../../components/product";
import ProductList from "../../components/productList";
import { formatNumberZero } from "../../services/function";
import Badge from "../../components/badge";
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from "../../services/auth-header";
import productSrv from "../../services/productSrv";
import { HeaderContext } from "../../context/headerContex";
import RewardSlide from "../../components/rewardSlide";

const Reward = () => {
  let customer = sessionStorage.getItem('customer_profile') === null ? '' : JSON.parse(sessionStorage.customer_profile);
  let line_profile = sessionStorage.getItem('line_profile') === null ? '' : JSON.parse(sessionStorage.line_profile);
  const { Title } = Typography;
  const { Search } = Input;
  const { Option } = Select;
  const [data, setData] = useState(customer);
  const [lineProfile, setLineProfile] = useState(line_profile);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
  const [branchList, setBranchList] = useState([]);
  const [tabHistory, setTabHistory] = useState("all");
  const [page, setPage] = useState(1);
  const [preload, setPreload] = useState(false);
  const [form] = Form.useForm();
  const { setHeaderMenu } = useContext(HeaderContext);
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const [filter, setFilter] = useState({
    search: '',
    branch_id: '',
    categories: ''
  });
  useEffect(() => {
    setHeaderMenu("new_arrivals");
    setIsLoading(true);
    const liff = window.liff;
    console.log('process.env.REACT_APP_LIFFID_REWARD', process.env.REACT_APP_LIFFID_REWARD);
    if (line_profile == '') {
      getData(liff, process.env.REACT_APP_LIFFID_REWARD);
      // getData(liff, '2002607214-EODO0GXr');
    }
    else {
      console.log(customer);
      if (customer == '') {
        getProfile();
      }
    }

  }, []);
  const getData = (liff, liffId) => {
    setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');

    main(liff, liffId).then(profile => {
      if (profile) {
        console.log(profile);
        setLoadingMsg('กำลังตรวจสอบข้อมูลลูกค้า...');
        setLineProfile(profile);
        if (typeof (Storage) !== "undefined") {
          sessionStorage.line_profile = JSON.stringify(profile);
        }
        if (customer == '') {

          getCustomer(profile.userId).then(response => {
            console.log('getCustomer', response);
            if (response.data.success) {
              if (response.data.data) {
                setData(response.data.data);
                let res = response.data.data;
                sessionStorage.customer_profile = JSON.stringify(res);
                sessionStorage.is_customer = 1;
              }
              else {
                sessionStorage.is_customer = 0;
                setIsLoading(false);
              }
            }
          }).catch(err => {
            setIsLoading(false);
            responseHandleSrv.handleError(err);
          });
        } else {

          setData(customer);
        }
      }
    })
  };
  const main = async (liff, liffId) => {
    await liff.init({ liffId: `${liffId}` }).catch(err => { throw err });
    if (liff.isLoggedIn()) {
      return getUserProfile(liff);
    }
    else {
      return liff.login();
    }
  }
  const getUserProfile = async (liff) => {
    return await liff.getProfile();
  }
  const getCustomer = async (uid) => {
    return await axios_json.get(`${Api.CUSTOMER_PROFILE_BY_LINE + uid}`, {
      headers: authBasicHeader()
    })
  };

  const getProfile = () => {
    console.log('lineProfile', lineProfile);
    getCustomer(lineProfile.userId).then(response => {
      if (response.data.success) {
        if (response.data.data) {
          setData(response.data.data);
          let res = response.data.data;
          sessionStorage.customer_profile = JSON.stringify(res);
          sessionStorage.is_customer = 1;
        }
        else {
          sessionStorage.is_customer = 0;
          setIsLoading(false);
        }
      }
    }).catch(err => {
      setIsLoading(false);
      responseHandleSrv.handleError(err);
    });
  }
  useEffect(() => {
    setHeaderMenu("new_arrivals");
    getBranchList();

  }, []);
  
  const getBranchList = () => {

    (async () => {
      await productSrv.branchList().then(response => {
        // console.log('getBranchList', response.data);
        if (response.data.success) {
          let res = response.data.data;
          setBranchList(res);
          console.log('res', res);
          setFilter((val) => ({
            ...val,
            branch_id: res[0].id
          }));

          form.setFieldsValue({
            'branch': res[0].id,
          });
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      });
    })();
  }

  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const rewardTab = [
    { key: "all", label: "ทั้งหมด", children: <ProductList type="all" tab={tabHistory} start={page} search={filter.search} branch_id={filter.branch_id} /> },
    { key: "bag", label: "กระเป๋า", children: <ProductList type="bag" tab={tabHistory} start={page} search={filter.search} branch_id={filter.branch_id} /> },
    { key: "dom", label: "Dom", children: <ProductList type="dom" tab={tabHistory} start={page} search={filter.search} branch_id={filter.branch_id} /> },
    { key: "toy", label: "ของเล่น", children: <ProductList type="toy" tab={tabHistory} start={page} search={filter.search} branch_id={filter.branch_id} /> },
  ];
  const callback = (key) => {
    setTabHistory(key);
    setPage(1);
  }
  const OnChangeBranch = (value) => {
    setFilter((val) => ({
      ...val,
      branch_id: value
    }));
  }
  return (
    <>
        <Flex justify={"space-around"} align={"center"} style={{marginTop:"10px"}}>
        <RewardSlide />
      </Flex>
      <Row gutter={[10, 10]}>
        <Col xs={24} style={{ textAlign: "center" }}>
          <Title level={4}>ของสัมนาคุณที่ระลึก</Title>
        </Col>
        <Col xs={22} offset={1} style={{ textAlign: "center" }}>
          {/* <Search placeholder="search text" allowClear onSearch={onSearch} /> */}
          <Form
            form={form}
          >
            <Form.Item
              name="branch"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                placeholder="Select a option and change input text above"
              // allowClear
              // onChange={OnChangeBranch}
              onChange={OnChangeBranch} style={{border:"0px solid"}}

              >
                {branchList && branchList.map((s) => <Option value={s.id} key={s.id}>{s.name_th}</Option>)}
              </Select>
            </Form.Item>
          </Form>
        </Col>

        <Col span={22} offset={1}></Col>
        
        {/* {customer != "" && (
        <Col span={22} offset={2}>
          <Space style={{ width: "100%" }}>
            <Badge type="stamp" point={formatNumberZero(data.stamp, 0)} />
            <Badge type="point" point={formatNumberZero(data.point, 0)} />
            <Badge type="coin" point={formatNumberZero(data.coin, 0)} />
          </Space>
        </Col>
        )} */}
        <Col span={22} offset={1}>
        <Tabs defaultActiveKey={tabHistory} items={rewardTab} centered size="large" onChange={callback} destroyInactiveTabPane={true} />
        </Col>
      </Row>
    </>
  );
};
export default Reward;

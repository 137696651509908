import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Notfound from "../pages/404";
import Main from "../layout/main";
import User from "../pages/register/user";
import Signup from "../pages/register/signup";
import Verify from "../pages/register/verify";
import Signin from "../pages/register/signin";
import Forgotpassword from "../pages/register/forgotpassword";
import WebFormRegister from "../pages/webForm/stepsForm";
import Resetpassword from "../pages/register/resetpassword";
import FormRegister from "../pages/lineForm/stepsForm";
import Profile from "../pages/lineForm/profileHome";
import ProfileHome from "../pages/profile/profileHome";
import History from "../pages/profile/history";
import Consent from "../pages/consent";
import ProductHome from "../pages/product/home";
import MiddleProfile from "./middleProfile";
import MiddleHistory from "./middleHistory";
import MiddleReward from "./middleReward";
import MiddleProduct from "./middleProduct";
import Reward from "../pages/product/reward";
import LineProduct from "../pages/lineForm/productHome";
import LineRewaed from "../pages/lineForm/reward";
import Policy from "../pages/policy";
import Setting from "../pages/profile/setting";
import ChangePassword from "../pages/profile/changepassword";
import Detail from "../pages/profile/detail";
import ProfileEdit from "../pages/profile/edit";
import Skill from "../pages/profile/skill";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "/", element: <MiddleReward />, index: true },
      { path: "signin", element: <Signin /> },
      { path: "forgotpwd", element: <Forgotpassword /> },
      { path: "resetpassword", element: <Resetpassword /> },
      { path: "line/register", element: <FormRegister /> },
      { path: "line/profile", element: <Profile /> },
      { path: "line/product", element: <LineProduct /> },
      { path: "line/reward", element: <LineRewaed /> },
      { path: "consent", element: <Consent /> },
      { path: "policy", element: <Policy /> },
      { path: "*", element: <Notfound /> },
      {
        path: "/register",
        children: [
          { path: "user", element: <User /> },
          { path: "signup", element: <Signup /> },
          { path: "verify", element: <Verify /> },
          { path: "home", element: <WebFormRegister /> },
        ],
      },
      {
        path: "/profile",
        children: [
          { path: "index", element: <MiddleProfile /> },
          { path: "detail", element: <Detail /> },
          { path: "edit", element: <ProfileEdit /> },
          { path: "home", element: <ProfileHome /> },
          { path: "history", element: <History /> },
          { path: "history/index", element: <MiddleHistory /> },
          { path: "setting", element: <Setting /> },
          { path: "changepassword", element: <ChangePassword /> },
          { path: "skill", element: <Skill /> },
        ],
      },
      {
        path: "/product",
        children: [
          { path: "index", element: <MiddleProduct /> },
          { path: "home", element: <ProductHome /> },
          { path: "reward", element: <Reward /> },
          { path: "reward/index", element: <MiddleReward /> },
        ],
      },
    ],
  },
]);
export default router;

import React from "react";
import ContextProvider from "./contextProvider";
import { CreateUserContextProvider } from "./createUser";
import { PreloadContextProvider } from "./preload";
import { HeaderContextProvider } from "./headerContex";

const CombinedContext = ({ children }) => {
  return (
    <ContextProvider
      contexts={[
        <CreateUserContextProvider key={"createUser_provider"} />,
        <PreloadContextProvider key={"preload_provider"} />,
        <HeaderContextProvider key={"header_provider"} />,
      ]}
    >
      {children}
    </ContextProvider>
  );
};

export default CombinedContext;

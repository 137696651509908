import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Flex, Button, Typography, Space, Tabs } from "antd";
import Badge from "../../components/badge";
import HistoryTabs from "../../components/historyTabs";
import { formatNumberZero } from "../../services/function";
import { authBasicHeader } from "../../services/auth-header";
import responseHandleSrv from "../../services/responseHandleSrv";
import { axios_json } from "../../axios";
import Api from "../../constants/api";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Input, Tooltip, Checkbox, Modal } from "antd";
import { HeaderContext } from "../../context/headerContex";

// let data = sessionStorage.getItem('customer_profile') === null ? '' : JSON.parse(sessionStorage.customer_profile);

// console.log('customer_profile', sessionStorage.getItem('customer_profile'));

export default function History() {
  let data =
    sessionStorage.getItem("customer_profile") === null
      ? ""
      : JSON.parse(sessionStorage.customer_profile);
  console.log("data", data);
  const { Title, Text } = Typography;
  const [tabHistory, setTabHistory] = useState("1");
  const [page, setPage] = useState(1);
  const { setHeaderMenu } = useContext(HeaderContext);
  useEffect(() => {
    setHeaderMenu("history");
  }, []);
  const historys = [
    {
      key: "1",
      label: "ทั้งหมด",
      children: <HistoryTabs tab={tabHistory} start={page} listdata={[]} />,
    },
    {
      key: "2",
      label: "การทำรายการ",
      children: <HistoryTabs tab={tabHistory} start={page} listdata={[]} />,
    },
    {
      key: "3",
      label: "การแลกของรางวัล",
      children: <HistoryTabs tab={tabHistory} start={page} listdata={[]} />,
    },
  ];
  function callback(key) {
    setTabHistory(key);
    setPage(1);
  }
  return (
    <>
      {/* <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
          <Title level={5}>ประวัติ</Title>
        </Col>
      </Row> */}
      <Row justify="center" gutter={[0, 10]}>
        {/* <Col span={24} style={{ textAlign: "center" }}>
          <Space size="middle">
            <Badge type="stamp" point={formatNumberZero(data.stamp, 0)} />
            <Badge type="point" point={formatNumberZero(data.point, 0)} />
            <Badge type="coin" point={formatNumberZero(data.coin, 0)} />
          </Space>
        </Col> */}

        <Col span={22} offset={2} pull={1}>
          <Tabs
            defaultActiveKey={tabHistory}
            items={historys}
            centered
            size="large"
            onChange={callback}
            destroyInactiveTabPane={true}
          />
        </Col>
      </Row>
    </>
  );
}

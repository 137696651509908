import React, { useState, useEffect,useContext } from "react";
import {
  Avatar,
  Card,
  Typography,
  Row,
  Col,
  Flex,
  Image,
  Form,
  Select,
  Input,
  DatePicker,
  Radio,
  Button,
  ConfigProvider,
} from "antd";
import locale from "antd/locale/zh_CN";
import dayjs from "dayjs";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { getCurrentCustomer, getCurrentUser } from "../../services/auth";
import { axios_json } from "../../axios";
import { authBasicHeader, authHeader } from "../../services/auth-header";
import Api from "../../constants/api";
import responseHandleSrv from "../../services/responseHandleSrv";
import { redirectProfile } from "../../services/function";
import icoMale from "../../assets/images/ico-male.svg";
import icoFemale from "../../assets/images/ico-female.svg";
import icoLgbt from "../../assets/images/ico-lgbt.svg";
import { HeaderContext } from "../../context/headerContex";


export default function Edit() {
  let data = getCurrentCustomer();
  let user = getCurrentUser();
  const { Title, Text } = Typography;
  const { Option } = Select;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [subList, setSubList] = useState([]);
  const [alertZipCode, setZipCode] = useState(false);
  const [addr, setAddr] = useState("");
  const dateFormat = "DD/MM/YYYY";
  const { setHeaderMenu } = useContext(HeaderContext);


  useEffect(() => {
    form.setFieldsValue({
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      // 'birthdate': data.birthdate,
      sex: data.sex,
      address1: data.address1,
      zipcode: data.zipcode,
      subdistrict_id:
        data.subdistrict_id != null && data.subdistrict_id != "null"
          ? data.subdistrict_id
          : null,
      district_name:
        data.district_name_th != null && data.district_name_th != "null"
          ? data.district_name_th
          : "",
      province_name:
        data.province_name_th != null && data.province_name_th != "null"
          ? data.province_name_th
          : "",
      mobile_no: data.mobile_no,
      username: data.username,
    });
    if (data.zipcode) {
      getZipCode(data.zipcode);
    }
    setValueBirthdate();
    setHeaderMenu("profileEdit");

  }, []);

  const setValueBirthdate = () => {
    if (data.birthdate) {
      let date_sp = data.birthdate.split("-");
      let date = date_sp[2] + "/" + date_sp[1] + "/" + date_sp[0];

      form.setFieldsValue({
        birthdate: dayjs(date, dateFormat),
      });
    } else {
      form.setFieldsValue({
        birthdate: "",
      });
    }
  };
  const onFinish = (values) => {
    console.log("onFinish", values);
    console.log("addr", addr);
    let date = values.birthdate.format("YYYY-MM-DD");
    console.log("date", date);
    let body = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      birthdate: date,
      mobile_no: values.mobile_no,
      username: values.username,
      is_consent: data.is_consent,
      line_id: data.line_id,
      line_uid: data.line_uid,
      sex: values.sex,
      address1: values.address1,
      address2: "",
      district_id:
        addr != ""
          ? addr.district_id
          : data.district_id != null && data.district_id != "null"
          ? data.district_id
          : "",
      subdistrict_id: values.subdistrict_id ? values.subdistrict_id : "",
      province_id:
        addr != ""
          ? addr.province_id
          : data.province_id != null && data.province_id != "null"
          ? data.province_id
          : "",
      zipcode: values.zipcode,
      description: data.description,
      role_id: data.role_id,
      is_verified_otp: data.is_verified_otp,
      is_active: data.is_active,
      picture_url: data.picture_url,
    };

    console.log(body);
    updateCust(body);
  };
  const onFinishFailed = () => {};

  const updateCust = (body) => {
    console.log("body", body);
    (async () => {
      await axios_json
        .put(`${Api.CUSTOMER + data.id}`, body, {
          headers: user != "" ? authHeader() : authBasicHeader(),
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
            responseHandleSrv.handleMsg(response.data.message);
            sessionStorage.removeItem("customer_profile");

            setTimeout(() => {
              redirectProfile();
            }, 1000);
          }
        })
        .catch((err) => {
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const postCodeChange = (e) => {
    if (e.target.value) {
      clearAddress();
      // setZipCode(true);
    } else {
      setSubList([]);
      clearAddress();
      setZipCode(false);
    }
  };
  const postCodeBlur = (e) => {
    if (e.target.value) {
      clearAddress();
      getZipCode(e.target.value);
    } else {
      setSubList([]);
      clearAddress();
      setZipCode(false);
    }
  };
  const clearAddress = () => {
    form.setFieldsValue({
      subdistrict_id: null,
      district_name: "",
      province_name: "",
    });
    setAddr((val) => ({
      ...val,
      subdistrict_id: null,
      province_id: null,
      province_name: null,
      district_id: null,
      district_name: null,
    }));
  };

  const getZipCode = (zipCode) => {
    setZipCode(true);
    console.log(zipCode);
    (async () => {
      await axios_json
        .get(`${Api.ADDRESS_FIND_ZIPCODE + zipCode}`, {
          headers: authBasicHeader(),
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
            setSubList(response.data.data);
          }
        })
        .catch((err) => {
          setZipCode(false);
          setSubList([]);
          responseHandleSrv.handleErrorMsg(
            "เกิดข้อผิดพลาด",
            "รหัสไปรษณีย์ไม่ถูกต้อง"
          );
        });
    })();
  };
  const subChange = (value) => {
    let find = subList.find((e) => e.subdistrict_id == value);

    if (Object.entries(find).length > 0) {
      // setSubDistrictProp(value);
      form.setFieldsValue({
        district_name: find.district_name_th,
        province_name: find.province_name_th,
      });

      setAddr((val) => ({
        ...val,
        district_id: find.district_id,
        district_name: find.district_name_th,
        province_id: find.province_id,
        province_name: find.province_name_th,
      }));
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonBg: "#000",
            buttonColor: "#FFF",
          },
          Form: {
            labelColor: "#7745FF",
          }
        },
      }}
    >
      <>
        <Row justify={"center"} gutter={[10, 10]} style={{ marginTop: "20px" }}>
          <Col span={16} style={{ textAlign: "center" }}>
            <Title level={4}>แก้ไขข้อมูลส่วนตัว</Title>
          </Col>
          <Col span={6} pull={2}>
            <Image src={data.picture_url} style={{ borderRadius: "15px" }} />
          </Col>
        </Row>
        <Form
          form={form}
          name="signUp"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // initialValues={worker}
        >
          <Row justify="center" gutter={[0, 10]}>
            <Col span={22}>
              <div>
                <Form.Item
                  name="firstname"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      ชื่อ
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: "ชื่อ",
                    },
                  ]}
                >
                  <Input placeholder="ชื่อ" />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="lastname"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      นามสกุล
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: "นามสกุล",
                    },
                  ]}
                >
                  <Input placeholder="นามสกุล" />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="email"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      อีเมล
                    </label>
                  }
                  rules={[
                    {
                      required: false,
                      message: "อีเมล",
                    },
                    {
                      type: "email",
                      message: "รูปแบบ email ไม่ถูกต้อง",
                    },
                  ]}
                >
                  <Input placeholder="email" />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="username"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      username
                    </label>
                  }
                >
                  <Input placeholder="username" disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="mobile_no"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      เบอร์โทรศัพท์
                    </label>
                  }
                >
                  <Input placeholder="เบอร์โทรศัพท์" disabled />
                </Form.Item>
              </div>
            </Col>

            <Col span={22}>
              <div>
                <Form.Item
                  name="birthdate"
                  // onChange={}
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      วันเกิด
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: "วันเกิด",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={22}>
              <div>
                <Form.Item
                  name="sex"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      เพศ
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: "เพศ",
                    },
                  ]}
                >
                  {/* <Radio.Group>
                    <Radio value="male">ชาย</Radio>
                    <Radio value="female">หญิง</Radio>
                    <Radio value="other">ไม่ระบุ</Radio>
                  </Radio.Group> */}
                  <Radio.Group buttonStyle="solid" size={"large"} style={{display:"Flex",justifyContent:"center",width:"100%"}}>
                    <Radio.Button value="male" style={{height:"80px",width:"33%",textAlign:"center",borderRadius:"0px"}}>
                      <Image src={icoMale} preview={false} />
                      <p style={{ margin: "0px" }}>ชาย</p>
                    </Radio.Button>
                    <Radio.Button value="female" style={{height:"80px",width:"33%",textAlign:"center",borderRadius:"0px",marginLeft:"5px",marginRight:"5px"}}>
                      <Image src={icoFemale} preview={false} />
                      <p style={{ margin: "0px" }}>หญิง</p>
                    </Radio.Button>
                    <Radio.Button value="other" style={{height:"80px",width:"33%",textAlign:"center",borderRadius:"0px"}}>
                      <Image src={icoLgbt} preview={false} />
                      <p style={{ margin: "0px" }}>ไม่ระบุ</p>
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="address1"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      ที่อยู่
                    </label>
                  }
                  rules={[
                    {
                      required: false,
                      message: "ที่อยู่",
                    },
                  ]}
                >
                  <Input placeholder="ที่อยู่" />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="zipcode"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      รหัสไปรษณีย์
                    </label>
                  }
                  rules={[
                    {
                      required: false,
                      message: "กรอกรหัสไปรษณีย์",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "ตัวเลขเท่านั้น",
                    },
                    {
                      min: 5,
                      message: "ความยาว 5 ตัวอักษร",
                    },
                  ]}
                >
                  <Input
                    onBlur={postCodeBlur}
                    onChange={postCodeChange}
                    placeholder="รหัสไปรษณีย์"
                    maxLength={5}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="subdistrict_id"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      ตำบล/แขวง
                    </label>
                  }
                  rules={[
                    {
                      required: alertZipCode,
                      message: "เลือกตำบล/แขวง",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="เลือกตำบล/แขวง"
                    style={{ width: "100%" }}
                    // onChange={selectChange('subdistrict_id')}
                    onSelect={subChange}
                    disabled={!subList || subList.length == 0}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {subList &&
                      subList.map((s) => (
                        <Option value={s.subdistrict_id} key={s.subdistrict_id}>
                          {s.subdistrict_name_th}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="district_name"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      อำเภอ/เขต
                    </label>
                  }
                  rules={[
                    {
                      required: false,
                      message: "อำเภอ/เขต",
                    },
                  ]}
                >
                  <Input placeholder="อำเภอ/เขต" disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="province_name"
                  label={
                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                      จังหวัด
                    </label>
                  }
                  rules={[
                    {
                      required: false,
                      message: "จังหวัด",
                    },
                  ]}
                >
                  <Input placeholder="จังหวัด" disabled />
                </Form.Item>
              </div>
            </Col>

            <Col
              span={22}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  ยืนยัน
                </Button>
              </Form.Item>
              {/* <div className="box-btn-back">
               <div className="text-left">
                 <Button onClick={prevStep}>ย้อนกลับ</Button>
               </div>
               <div className="text-right">
                 <Form.Item
                 >
                   <Button type='primary' htmlType="submit" >ยืนยัน</Button>
                 </Form.Item>
               </div>
             </div> */}
            </Col>
          </Row>
        </Form>
      </>
    </ConfigProvider>
  );
}

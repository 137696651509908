import React from "react";
import { Navigate } from "react-router-dom";
import Signin from "../pages/register/signin";
import ProfileLine from "../pages/lineForm/profileHome";
import ProfileHome from "../pages/profile/profileHome";

export default function MiddleProfile() {
// console.log('session',sessionStorage.getItem('is_customer'));
  if (sessionStorage.getItem("line_profile") && sessionStorage.getItem('is_customer') == '1') {
      return <Navigate to="/line/profile" />;
  } else if (sessionStorage.getItem("user")) {
    return <Navigate to="/profile/home" />;
  } else {
    return <Navigate to="/signin" />;
  }
}

import React from "react";
import { Avatar, Card, Typography, Row, Col, Flex, Image, Button } from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getCurrentCustomer } from "../../services/auth";
import moment from "moment";
import { sexObject } from "../../services/function";
import Barcode from "react-barcode";

export default function Detail() {
  const { Title, Text } = Typography;
  let data = getCurrentCustomer();
  console.log("detail", data);

  const address = () => {
    if (data.address1) {
      if (data.subdistrict_name_th) {
        return (
          <>
            {data.address1} {data.subdistrict_name_th} {data.district_name_th}{" "}
            <br /> {data.province_name_th} {data.zipcode}
          </>
        );
      } else {
        return <>{data.address1}</>;
      }
    } else {
      if (data.subdistrict_name_th) {
        return (
          <>
            {data.subdistrict_name_th} {data.district_name_th} <br />{" "}
            {data.province_name_th} {data.zipcode}
          </>
        );
      } else {
        return <>-</>;
      }
    }
  };
  return (
    <Row justify={"center"} gutter={[10, 10]}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Title level={4}>โปรไฟล์</Title>
      </Col>
      <Col span={24} style={{ textAlign: "center" }}>
        {/* <Avatar size={98} icon={<UserOutlined />} /> */}
        {data.picture_url ? (
          <Avatar size={98} icon={<UserOutlined />} src={data.picture_url} />
        ) : (
          <Avatar size={98} icon={<UserOutlined />} />
        )}
      </Col>
      {data.mobile_no && (
        <Col span={24} offset={2} pull={1} style={{ textAlign: "center" }}>
          <Barcode
            value={data.mobile_no}
            height={30}
            fontSize={10} 
          />
        </Col>
      )}
      <Col span={22} offset={2} pull={1}>
        <Card>
          <Flex justify={"space-between"}>
            <Title level={5} className={"title-h5"}>
              ข้อมูลส่วนตัว
            </Title>
            <Link to={"/profile/edit"}>
              <EditOutlined style={{ fontSize: "18px", color: "#00B19C" }} />
            </Link>
          </Flex>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>ชื่อ นามสกุล</Text>
                <Text type={"secondary"}>
                  {data.firstname} {data.lastname}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>เบอร์โทรศัพท์</Text>
                <Text type={"secondary"}>{data.mobile_no}</Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>วันเกิด</Text>
                <Text type={"secondary"}>
                  {data.birthdate
                    ? moment(data.birthdate).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>เพศ</Text>
                <Text type={"secondary"}>
                  {data.sex ? sexObject[data.sex] : "-"}
                </Text>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={22} offset={2} pull={1}>
        <Card>
          <Flex justify={"space-between"}>
            <Title level={5} className={"title-h5"}>
              ข้อมูลติดต่อ
            </Title>
          </Flex>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>อีเมล</Text>
                <Text type={"secondary"}>{data.email ? data.email : "-"}</Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>เบอร์โทรศัพท์</Text>
                <Text type={"secondary"}>{data.mobile_no}</Text>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={22} offset={2} pull={1}>
        <Card>
          <Flex justify={"space-between"}>
            <Title level={5} className={"title-h5"}>
              ที่อยู่
            </Title>
          </Flex>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>รายละเอียด</Text>
                <Text type={"secondary"}>
                  {address()}
                  {/* 1 soi 2 vipavade Rd. <br /> BKK 10210{" "} */}
                </Text>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={22} offset={2} pull={1}>
        <Card>
          <Flex justify={"space-between"}>
            <Title level={5} className={"title-h5"}>
              ข้อมูลส่วนตัวเพิ่มเติม
            </Title>
          </Flex>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>username</Text>
                <Text type={"secondary"}>
                  {data.username ? data.username : "-"}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text type={"secondary"}>Line id</Text>
                <Text type={"secondary"}>
                  {data.line_id ? data.line_id : "-"}
                </Text>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Card, Input, Button, Form } from "antd";
import { RightOutlined } from "@ant-design/icons"
import { Link, Navigate } from "react-router-dom";
import { axios_json } from "../../axios";
import { authHeader, authBasicHeader } from "../../services/auth-header";
import Api from "../../constants/api";
import responseHandleSrv from "../../services/responseHandleSrv";
import { redirectProfile } from "../../services/function";
import { HeaderContext } from "../../context/headerContex";

const ChangePassword = () => {
  let customer = sessionStorage.getItem('customer_profile') === null ? '' : JSON.parse(sessionStorage.customer_profile);
  let user = sessionStorage.getItem("user") === null ? "" : JSON.parse(sessionStorage.user);
  const root = window.location.origin;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { setHeaderMenu } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderMenu("changepass");
  }, [])
  const onFinish = (value) => {
    console.log('onFinish', value);

    changePass(value);
  };
  const onFinishFailed = () => { };

  const changePass = (value) => {

    let data = {
      username: customer.username,
      old_pass: value.old_password,
      new_pass: value.confirm
    };
    (async () => {
      await axios_json.post(`${Api.CUSTOMER_CHANGE_PASS}`, data, {
        headers: user != "" ? authHeader() : authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          responseHandleSrv.handleMsg(response.data.message);
          
          setTimeout(() => {
            redirectProfile();
        }, 1000);
        }
      }).catch(err => {
        responseHandleSrv.handleError(err);
      })
    })();
  };


  return (
    <>
      <Form
        form={form}
        name="changepass"
        onFinish={onFinish}
        // onFinishFailed={responseHandleSrv.onFinishFailed}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[10, 10]} justify={"center"}>
          <Col span={22} offset={1} style={{ textAlign: "center" }}>
            <Title level={4}>เปลี่ยนรหัสผ่าน</Title>
          </Col>
          <Col span={22} >
            <div>
              {/* <Typography.Title level={5} className="title-h5">รหัสผ่านปัจจุบัน</Typography.Title>
              <Input.Password placeholder="Password" /> */}
              <Form.Item
                name="old_password"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัสผ่านปัจจุบัน</label>}
                rules={[
                  {
                    required: true,
                    message: 'รหัสผ่านปัจจุบัน'

                  }
                ]}
              >
                <Input.Password placeholder="current Password" />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} >
            <div>
              {/* <Typography.Title level={5} className="title-h5">รหัสผ่าน</Typography.Title>
              <Input.Password placeholder="Password" /> */}
              <Form.Item
                name="new_password"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัสผ่าน</label>}
                rules={[
                  {
                    required: true,
                    message: 'รหัสผ่าน'

                  }
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} >
            <div>
              {/* <Typography.Title level={5} className="title-h5">ยืนยันรหัสผ่าน</Typography.Title>
              <Input.Password placeholder="Confirm password" /> */}
              <Form.Item
                name="confirm"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>ยืนยันรหัสผ่าน</label>}
                // onChange={inputChange('confirm')}
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'ยืนยันรหัสผ่าน',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm password" />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} >
            <Button type="primary" htmlType="submit" block style={{ textAlign: "center", position: "fixed", bottom: "100px", width: "92%" }}>
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ChangePassword;

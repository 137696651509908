import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Flex,
  Button,
  Typography,
  Space,
  Tabs,
  Card,
  Image,
  Tag,
} from "antd";
import Badge from "../../components/badge";
import HistoryTabs from "../../components/historyTabs";
import { formatNumberZero } from "../../services/function";
import { authBasicHeader } from "../../services/auth-header";
import responseHandleSrv from "../../services/responseHandleSrv";
import { axios_json } from "../../axios";
import Api from "../../constants/api";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Input, Checkbox, Modal } from "antd";
import { HeaderContext } from "../../context/headerContex";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  canvasElement,
  Scale,
  scales,
  Ticks,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import power from "../../assets/images/power.svg";
import agility from "../../assets/images/agility.svg";
import reaction from "../../assets/images/reaction.svg";
import balance from "../../assets/images/balance.svg";
import speed from "../../assets/images/speed.svg";
import { callback } from "chart.js/helpers";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

// let data = sessionStorage.getItem('customer_profile') === null ? '' : JSON.parse(sessionStorage.customer_profile);

// console.log('customer_profile', sessionStorage.getItem('customer_profile'));

export default function Skill() {
  let data =
    sessionStorage.getItem("customer_profile") === null
      ? ""
      : JSON.parse(sessionStorage.customer_profile);
  console.log("data", data);
  const { Title, Text } = Typography;
  const [tabHistory, setTabHistory] = useState("1");
  const [page, setPage] = useState(1);
  const { setHeaderMenu } = useContext(HeaderContext);
  const [mSkills, setMSkills] = useState({});

  useEffect(() => {
    setHeaderMenu("joyskill");
    console.log("joyskill xxx");
    if (data !== null) {
      setMSkills({
        skill_1: data.m_skill1,
        skill_2: data.m_skill2,
        skill_3: data.m_skill3,
        skill_4: data.m_skill4,
        skill_5: data.m_skill5,
      });
    }
  }, []);
  console.log(`skill1 ${mSkills.skill_1}`);
  const skills = {
    labels: ["POWER", "AGILITY", "REACTION TIME", "BALANCE", "SPEED"],
    datasets: [
      {
        display: false,
        data: [
          mSkills.skill_1,
          mSkills.skill_2,
          mSkills.skill_3,
          mSkills.skill_4,
          mSkills.skill_5,
        ],
        fill: true,
        backgroundColor: "rgba(118, 44, 255, 0.4)",
        borderColor: "rgb(118, 44, 255)",
        pointBackgroundColor: "rgb(118, 44, 255)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(118, 44, 255)",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      customCanvasBackgroundColor: {
        color: "lightGreen",
      },
    },    
    scales: {
      r: {
        ticks: {
          display: false,
        },   
        grid: {
          display: true,
          circular:false
        },
        
      }
    },
  };
  return (
    <>
      <Card
        style={{
          margin: "0px 15px",
          position: "relative",
          position: "relative",
          border: "0px",
        }}
        bodyStyle={{
          background: "linear-gradient(#FFF, rgb(118, 44, 255,0.2))",
          padding: "5px",
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
      >
        <Radar data={skills} options={options} />
      </Card>
      <Card
        style={{ margin: "0px 15px", position: "relative" }}
        bodyStyle={{ backgroundColor: "#DFE0E1", padding: "5px" }}
        title="STATS"
        headStyle={{
          backgroundColor: "#000",
          color: "#FFF",
          textAlign: "center",
        }}
      >
        <Flex justify={"center"} align={"center"}>
          <span>
            <Text
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingRight: "3px",
              }}
            >
              Rank
            </Text>
            <Text
              style={{
                color: "#752CFF",
                fontSize: "25px",
                fontWeight: "400",
                paddingRight: "3px",
              }}
            >
              00001
            </Text>
          </span>
        </Flex>
      </Card>
      <Card
        style={{ margin: "0", position: "relative" }}
        bodyStyle={{ padding: "0px" }}
      >
        <Flex
          justify={"space-between"}
          style={{
            backgroundColor: "#EA3323",
            padding: "15px 15px",
            margin: "0px 15px",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Col span={6}>
            <Image src={power} />
          </Col>
          <Col span={12}>
            <span>
              <Title level={3} style={{ margin: "0px", textAlign: "left" }}>
                POWER
              </Title>
              <Text>(พละกำลัง)</Text>
            </span>
          </Col>
          <Col span={6}>
            <Tag
              style={{
                backgroundColor: "#000",
                width: "70px",
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "45px",
              }}
            >
              {mSkills.skill_1}
            </Tag>
          </Col>
        </Flex>
        <Flex
          justify={"space-between"}
          style={{
            backgroundColor: "#6DEA7A",
            padding: "15px 15px",
            margin: "0px 15px",
            borderRadius: "10px",
          }}
        >
          <Col span={6}>
            <Image src={agility} />
          </Col>
          <Col span={12}>
            <span style={{ textAlign: "left" }}>
              <Title level={3} style={{ margin: "0px" }}>
                AGILITY
              </Title>
              <Text>(ความคล่องตัว)</Text>
            </span>
          </Col>
          <Col span={6}>
            <Tag
              style={{
                backgroundColor: "#000",
                width: "70px",
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "45px",
              }}
            >
              {mSkills.skill_2}
            </Tag>
          </Col>
        </Flex>
        <Flex
          justify={"space-between"}
          style={{
            backgroundColor: "#F9E45A",
            padding: "15px 15px",
            margin: "0px 15px",
            borderRadius: "10px",
          }}
        >
          <Col span={6}>
            <Image src={reaction} />
          </Col>
          <Col span={12}>
            <span>
              <Title level={3} style={{ margin: "0px" }}>
                REACTION TIME
              </Title>
              <Text>(ปฎิกรียาตอบสนอง)</Text>
            </span>
          </Col>
          <Col span={6}>
            <Tag
              style={{
                backgroundColor: "#000",
                width: "70px",
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "45px",
              }}
            >
              {mSkills.skill_3}
            </Tag>
          </Col>
        </Flex>
        <Flex
          justify={"space-between"}
          style={{
            backgroundColor: "#447FE6",
            padding: "15px 15px",
            margin: "0px 15px",
            borderRadius: "10px",
          }}
        >
          <Col span={6}>
            <Image src={balance} />
          </Col>
          <Col span={12}>
            <span>
              <Title level={3} style={{ margin: "0px" }}>
                BALANCE
              </Title>
              <Text>(ความสมดุล)</Text>
            </span>
          </Col>
          <Col span={6}>
            <Tag
              style={{
                backgroundColor: "#000",
                width: "70px",
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "45px",
              }}
            >
              {mSkills.skill_4}
            </Tag>
          </Col>
        </Flex>
        <Flex
          justify={"space-between"}
          style={{
            backgroundColor: "#E6742E",
            padding: "15px 15px",
            margin: "0px 15px",
            borderRadius: "10px",
          }}
        >
          <Col span={6}>
            <Image src={speed} />
          </Col>
          <Col span={12}>
            <span>
              <Title level={3} style={{ margin: "0px" }}>
                SPEED
              </Title>
              <Text>(ความเร็ว)</Text>
            </span>
          </Col>
          <Col span={6}>
            <Tag
              style={{
                backgroundColor: "#000",
                width: "70px",
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "45px",
              }}
            >
              {mSkills.skill_5}
            </Tag>
          </Col>
        </Flex>
      </Card>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Flex,
  Button,
  Typography,
  Input,
  Tooltip,
  Checkbox,
  Modal,
  DatePicker,
  Radio,
  Select,
  Form, Image, ConfigProvider
} from "antd";
import dayjs from "dayjs";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import StepPage from './stepPage';
import { axios_json } from "../../axios";
import { authBasicHeader } from "../../services/auth-header";
import Api from "../../constants/api";
import responseHandleSrv from '../../services/responseHandleSrv';
import moment from "moment";
import icoMale from "../../assets/images/ico-male.svg";
import icoFemale from "../../assets/images/ico-female.svg";
import icoLgbt from "../../assets/images/ico-lgbt.svg";

const StepTwoCheckData = ({ nextStep, prevStep, values, inputChange, setBirthdate, clearAddressProp,
  selectChange, setAddressProp, setRefProp, setOtpProp }) => {
  const { Option } = Select;
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [subList, setSubList] = useState([]);
  const [alertZipCode, setZipCode] = useState(false)
  const dateFormat = "DD/MM/YYYY";
  useEffect(() => {
    if (values.id > 0) {
      if (values.zipcode) {
        getZipCode(values.zipcode);
      }
      form.setFieldsValue({
        'firstname': values.firstname,
        'lastname': values.lastname,
        'email': values.email,
        'birthdate': values.birthdate,
        'sex': values.sex,
        'address1': values.address1,
        'zipcode': values.zipcode,
        'subdistrict_id': values.subdistrict_id,
        'district_name': values.district_name,
        'province_name': values.province_name,
        'mobile_no': values.mobile_no,
        'username': values.mobile_no
      });

      setValueBirthdate();
    } else {
      form.setFieldsValue({
        'mobile_no': values.mobile_no,
        'username': values.mobile_no
      });
    }

  }, []);
  const setValueBirthdate = () => {

    if (values.birthdate) {
      let date_sp = values.birthdate.split('-');
      let date = date_sp[2] + '/' + date_sp[1] + '/' + date_sp[0];

      form.setFieldsValue({
        'birthdate': dayjs(date, dateFormat)
      });
    } else {
      form.setFieldsValue({
        'birthdate': ''
      });
    }
  }
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }
  const onFinish = (valueForm) => {
    console.log('valueForm', valueForm);
    console.log('data', values);

    let date = valueForm.birthdate.format('YYYY-MM-DD');
    let body = {
      id: values.id,
      firstname: valueForm.firstname,
      lastname: valueForm.lastname,
      email: valueForm.email ? valueForm.email : "",
      birthdate: date,
      mobile_no: valueForm.mobile_no,
      username: valueForm.username,
      is_consent: values.is_consent,
      line_id: values.line_id,
      line_uid: values.line_uid,
      sex: valueForm.sex,
      address1: valueForm.address1 ? valueForm.address1 : "",
      address2: "",
      district_id: values.district_id != null ? values.district_id : "",
      subdistrict_id: valueForm.subdistrict_id ? valueForm.subdistrict_id : "",
      province_id: values.province_id != null ? values.province_id : "",
      zipcode: valueForm.zipcode ? valueForm.zipcode : "",
      description: values.description,
      role_id: values.role_id,
      is_verified_otp: true,
      is_active: true,
      picture_url: values.picture_url
    };

    if (valueForm.password != undefined) {
      body.password = valueForm.password;
    }
    console.log('body', body);
    if (values.id == 0) {
      register(body);
    } else {
      updateCust(body);
    }

  }
  const onFinishFailed = () => {

  }
  const register = (data) => {
    console.log('register', data);
    (async () => {
      await axios_json.post(`${Api.CUSTOMER_CREATE}`, data, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          nextStep();
        }
      }).catch(err => {
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const updateCust = (body) => {
    console.log('body', body);
    (async () => {
      await axios_json.put(`${Api.CUSTOMER + body.id}`, body, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          if (body.line_uid) {
            addUserRichMenu(body.line_uid)
          } else {
            nextStep();
          }
        }
      }).catch(err => {
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const addUserRichMenu = (lineId) => {
    let data = { lineUid: lineId };
    (async () => {
      await axios_json.post(`${Api.UTILITY_ADD_USER}`, data, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {

          nextStep();
        }
      }).catch(err => {
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const postCodeChange = (e) => {
    if (e.target.value) {
      clearAddress();
    }
    else {
      setSubList([]);
      clearAddress();
    }
  };
  const postCodeBlur = (e) => {
    if (e.target.value) {
      clearAddress();
      getZipCode(e.target.value);
    }
    else {
      setSubList([]);
      clearAddress();
    }
  };
  const clearAddress = () => {
    clearAddressProp();
    form.setFieldsValue({
      'subdistrict_id': null,
      'district_name': '',
      'province_name': ''
    });
  }
  const getZipCode = (zipCode) => {
    console.log(zipCode);
    (async () => {
      await axios_json.get(`${Api.ADDRESS_FIND_ZIPCODE + zipCode}`, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          setSubList(response.data.data);
        }
      }).catch(err => {
        setZipCode(true);
        setSubList([]);
        responseHandleSrv.handleErrorMsg('เกิดข้อผิดพลาด', 'รหัสไปรษณีย์ไม่ถูกต้อง');

      })
    })();
  };
  const subChange = (value) => {
    let find = subList.find(e => e.subdistrict_id == value);

    if (Object.entries(find).length > 0) {
      // setSubDistrictProp(value);
      form.setFieldsValue({
        'district_name': find.district_name_th,
        'province_name': find.province_name_th
      });
      setAddressProp(find);
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonBg: "#000",
            buttonColor: "#FFF",
          },
          Form: {
            labelColor: "#7745FF",
          }
        },
      }}
    >
      <>
        <StepPage step={2} />
        <Form
          form={form}
          name="signUp"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row justify="center" gutter={[0, 10]}>
            <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
              <Title level={5}>โปรดระบุรายละเอียด</Title>
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 10]}>
            <Col span={22}>
              <div>
                <Form.Item
                  name="firstname"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>ชื่อ</label>}
                  onChange={inputChange('firstname')}
                  rules={[
                    {
                      required: true,
                      message: 'ชื่อ'

                    }
                  ]}
                >
                  <Input placeholder="ชื่อ" value={values.firstname} />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="lastname"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>นามสกุล</label>}
                  onChange={inputChange('lastname')}
                  rules={[
                    {
                      required: true,
                      message: 'นามสกุล'

                    }
                  ]}
                >
                  <Input placeholder="นามสกุล" value={values.lastname} />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="email"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>อีเมล</label>}
                  onChange={inputChange('email')}
                  rules={[
                    {
                      required: false,
                      message: 'อีเมล'

                    },
                    {
                      type: 'email',
                      message: 'รูปแบบ email ไม่ถูกต้อง',
                    }
                  ]}
                >
                  <Input placeholder="email" value={values.email} />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="username"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>username</label>}
                >
                  <Input placeholder="username" disabled />
                </Form.Item>
              </div>
            </Col>
            {values.id == 0 &&
              <Col span={22} >
                <div>
                  <Form.Item
                    name="password"
                    label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัสผ่าน</label>}
                    onChange={inputChange('password')}
                    rules={[
                      {
                        required: true,
                        message: 'รหัสผ่าน'

                      }
                    ]}
                  >
                    <Input.Password placeholder="Password" value={values.password} />
                  </Form.Item>
                </div>
              </Col>
            }
            {values.id == 0 &&
              <Col span={22} >
                <div>

                  <Form.Item
                    name="confirm"
                    label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>ยืนยันรหัสผ่าน</label>}
                    onChange={inputChange('confirm')}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'ยืนยันรหัสผ่าน',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm password" />
                  </Form.Item>
                </div>
              </Col>
            }
            <Col span={22}>
              <div>
                <Form.Item
                  name="mobile_no"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>เบอร์โทรศัพท์</label>}
                >
                  <Input placeholder="เบอร์โทรศัพท์" disabled />
                </Form.Item>
              </div>
            </Col>

            <Col span={22}>
              <div>
                <Form.Item
                  name="birthdate"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>วันเกิด</label>}
                  onChange={setBirthdate}
                  onSelect={setBirthdate}
                  rules={[
                    {
                      required: true,
                      message: 'วันเกิด'

                    }
                  ]}

                >
                  <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={values.id > 0} inputReadOnly={true} />
                </Form.Item>
              </div>
            </Col>

            <Col span={22}>
              <div>
                <Form.Item
                  name="sex"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>เพศ</label>}
                  onChange={inputChange('sex')}
                  rules={[
                    {
                      required: true,
                      message: 'เพศ'

                    }
                  ]}
                >
                  {/* <Radio.Group>
                  <Radio value="male">ชาย</Radio>
                  <Radio value="female">หญิง</Radio>
                  <Radio value="other">ไม่ระบุ</Radio>
                </Radio.Group> */}
                  <Radio.Group buttonStyle="solid" size={"large"} style={{ display: "Flex", justifyContent: "center", width: "100%" }}>
                    <Radio.Button value="male" style={{ height: "80px", width: "33%", textAlign: "center", borderRadius: "0px" }}>
                      <Image src={icoMale} preview={false} />
                      <p style={{ margin: "0px" }}>ชาย</p>
                    </Radio.Button>
                    <Radio.Button value="female" style={{ height: "80px", width: "33%", textAlign: "center", borderRadius: "0px", marginLeft: "5px", marginRight: "5px" }}>
                      <Image src={icoFemale} preview={false} />
                      <p style={{ margin: "0px" }}>หญิง</p>
                    </Radio.Button>
                    <Radio.Button value="other" style={{ height: "80px", width: "33%", textAlign: "center", borderRadius: "0px" }}>
                      <Image src={icoLgbt} preview={false} />
                      <p style={{ margin: "0px" }}>ไม่ระบุ</p>
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="address1"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>ที่อยู่</label>}
                  onChange={inputChange('address1')}
                  rules={[
                    {
                      required: false,
                      message: 'ที่อยู่'

                    }
                  ]}
                >
                  <Input placeholder="ที่อยู่" value={values.address1} />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="zipcode"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัสไปรษณีย์</label>}
                  onChange={inputChange('zipcode')}
                  rules={[
                    {
                      required: false,
                      message: 'กรอกรหัสไปรษณีย์'
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: 'ตัวเลขเท่านั้น'
                    },
                    {
                      min: 5,
                      message: 'ความยาว 5 ตัวอักษร',
                    }
                  ]}
                >
                  <Input onBlur={postCodeBlur} onChange={postCodeChange} placeholder="รหัสไปรษณีย์" value={values.zipcode} maxLength={5} />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="subdistrict_id"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>ตำบล/แขวง</label>}
                  rules={[
                    {
                      required: false,
                      message: 'เลือกตำบล/แขวง'
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="เลือกตำบล/แขวง"
                    style={{ width: '100%' }}
                    onChange={selectChange('subdistrict_id')}
                    onSelect={subChange}
                    disabled={!subList || subList.length == 0}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {subList && subList.map((s) => <Option value={s.subdistrict_id} key={s.subdistrict_id}>{s.subdistrict_name_th}</Option>)}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                <Form.Item
                  name="district_name"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>อำเภอ/เขต</label>}
                  rules={[
                    {
                      required: false,
                      message: 'อำเภอ/เขต'

                    }
                  ]}
                >
                  <Input placeholder="อำเภอ/เขต" value={values.district_name} disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={22}>
              <div>
                {/* <Title level={5} className="title-h5">
                จังหวัด
              </Title>
              <Select placeholder="เลือกจังหวัด" className="width100" /> */}
                <Form.Item
                  name="province_name"
                  label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>จังหวัด</label>}
                  rules={[
                    {
                      required: false,
                      message: 'จังหวัด'

                    }
                  ]}
                >
                  <Input placeholder="จังหวัด" value={values.province_name} disabled />
                </Form.Item>
              </div>
            </Col>

            <Col span={22} style={{ textAlign: "center", marginBottom: "10px" }}>
              <Form.Item
              >
                <Button type='primary' htmlType="submit" block>ยืนยัน</Button>
              </Form.Item>
              {/* <div className="box-btn-back">
              <div className="text-left">
                <Button onClick={prevStep}>ย้อนกลับ</Button>
              </div>
              <div className="text-right">
                <Form.Item
                >
                  <Button type='primary' htmlType="submit" >ยืนยัน</Button>
                </Form.Item>
              </div>
            </div> */}
            </Col>
          </Row>
        </Form>
      </>
    </ConfigProvider>
  )
}

export default StepTwoCheckData

import React, { useEffect, useState, createContext } from 'react';
import StepUser from './stepUser';
import StepSignUp from './stepSignUp';
import StepOTP from './stepOTP';
import Success from './success';
import StepPage from './stepPage'
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from "../../services/auth-header";
import { Alert, Button, Space, Modal } from 'antd';
import moment from 'moment';
import {
    InfoCircleFilled
  } from "@ant-design/icons";

export default function StepsForm() {
    const [current, setCurrent] = useState(0);
    const [profile, setProfile] = useState({});
    // const [lineProfile, setLineProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
    // const [config, setConfig] = useState({});
    const [isExist, setExist] = useState(false);
    const [values, setValues] = useState({
        id: 0,
        firstname: "",
        lastname: "",
        email: "",
        birthdate: "",
        mobile_no: "",
        mobile_no_exist: "",
        username: "",
        password: "",
        confirm: "",
        is_consent: false,
        line_id: "",
        line_uid: "",
        sex: "",
        address1: "",
        address2: "",
        district_id: "",
        district_name: "",
        subdistrict_id: "",
        province_id: "",
        province_name: "",
        zipcode: "",
        description: "",
        is_verified_otp: false,
        ref_code: "",
        otp_code: "",
        countTime: 300,
        picture_url: "",
        role_id: 0
    });

    const root = window.location.origin;

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    useEffect(() => {
        setIsLoading(true);
        const liff = window.liff;
        // console.log(liff);
        getData(liff, process.env.REACT_APP_LIFFID_REGISTER);

        // getData(liff, '2002607214-WdNRlQJq');

    }, []);
    const getData = (liff, liffId) => {
        setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');

        main(liff, liffId).then(profile => {
            if (profile) {
                console.log(profile);
                setLoadingMsg('กำลังตรวจสอบข้อมูลลูกค้า...');
                // setLineProfile(profile);
                setLineUid(profile.userId);
                setPic(profile.pictureUrl);

                if (typeof (Storage) !== "undefined") {
                    sessionStorage.line_profile = JSON.stringify(profile);
                }
                getCustomer(profile.userId).then(response => {
                    console.log('get customer', response);
                    if (response.data.success) {
                        if (response.data.data) {
                            setProfile(response.data.data);
                            let res = response.data.data;
                            if (res.is_active === true && res.is_delete === false) {
                                // setCurrent(4);
                                setDataExit(response.data.data);
                                setIsLoading(false);
                                setExist(true);
                                // showModal();
                            }
                            sessionStorage.is_customer = 1;
                            
                            // sessionStorage.customer_profile = JSON.stringify(response.data.data);
                        }
                        else {
                            sessionStorage.is_customer = 0;
                            // setLineUid(profile.userId);
                            // setPic(profile.pictureUrl);
                            setCurrent(1);
                            setIsLoading(false);
                        }
                    }
                }).catch(err => {
                    setIsLoading(false);
                    responseHandleSrv.handleError(err);
                });
            }
        })
    };
    const main = async (liff, liffId) => {
        await liff.init({ liffId: `${liffId}` }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            return getUserProfile(liff);
        }
        else {
            return liff.login();
        }
    }
    const getUserProfile = async (liff) => {
        return await liff.getProfile();
    }
    const getCustomer = async (uid) => {
        return await axios_json.get(`${Api.CUSTOMER_PROFILE_BY_LINE + uid}`, {
            headers: authBasicHeader()
        })
    };
    const steps = [
        {
            title: 'First',
            content: 'first content'
        },
        {
            title: 'Second',
            content: 'Second-content',
        },
        {
            title: 'third',
            content: 'Third-content',
        },
        {
            title: 'Last',
            content: 'Last-content',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const setSaleProfile = (profile) => {
        setProfile(profile)
    }
    const setMobileNo = (mobileNo) => {
        let sale = { ...profile };
        sale.MobileNo = mobileNo
        setProfile(sale);
    }
    const goto = (num) => {
        setCurrent(num);
    }
    const inputChange = input => e => {
        setValues((val) => ({
            ...val,
            [input]: e.target.value
        }));

    };

    const selectChange = input => e => {
        setValues((val) => ({
            ...val,
            [input]: e
        }));
    };

    const setChkConsent = (e) => {
        setValues((val) => ({
            ...val,
            is_consent: e.target.checked
        }));
    };

    const setLineUid = (e) => {

        setValues((val) => ({
            ...val,
            line_uid: e
        }));
    };
    const setPic = (e) => {

        setValues((val) => ({
            ...val,
            picture_url: e
        }));
    };
    const setBirthdate = (e) => {
        console.log('setBirthdate', e);
        setValues((val) => ({
            ...val,
            birthdate: e.target.value
        }));
    };
    const clearAddress = () => {
        setValues((val) => ({
            ...val,
            subdistrict_id: null,
            province_id: null,
            province_name: null,
            district_id: null,
            district_name: null
        }));
    };
    const setAddress = (obj) => {
        setValues((val) => ({
            ...val,
            district_id: obj.district_id,
            district_name: obj.district_name_th,
            province_id: obj.province_id,
            province_name: obj.province_name_th
        }));
    };
    const setRef = (e) => {
        setValues((val) => ({
            ...val,
            ref_code: e
        }));
    };
    const setOtp = (e) => {
        setValues((val) => ({
            ...val,
            otp_code: e
        }));
    };

    const setDataExit = (e) => {
        setValues((val) => ({
            ...val,
            id: e.id,
            firstname: e.firstname,
            lastname: e.lastname,
            email: e.email,
            birthdate: e.birthdate,
            mobile_no: e.mobile_no,
            mobile_no_exist: e.mobile_no,
            username: e.username,
            sex: e.sex,
            address1: e.address1,
            address2: e.address2,
            district_id: e.district_id,
            subdistrict_id: e.subdistrict_id,
            province_id: e.province_id,
            zipcode: e.zipcode,
            description: e.description,
            district_name: (e.district_name_th != null && e.district_name_th != 'null') ? e.district_name_th : "",
            province_name: (e.province_name_th != null && e.province_name_th != 'null') ? e.province_name_th : "",
            role_id: e.role_id
        }))
    };

    switch (current) {
        case 1:
            return (
                <>
                    {/* <StepPage step={0} /> */}
                    <StepUser
                        nextStep={next}
                        prevStep={prev}
                        values={values}
                        inputChange={inputChange}
                        setChkConsentProp={setChkConsent}
                        goto={goto}
                        setRefProp={setRef}
                        setOtpProp={setOtp}
                        setDataExitProp={setDataExit}
                    />
                </>
            )
        case 2:
            return (
                <>
                    <StepOTP
                        nextStep={next}
                        prevStep={prev}
                        values={values}
                        setRefProp={setRef}
                        setOtpProp={setOtp}
                    />
                </>
            )
        case 3:
            return (
                <>
                    <StepSignUp
                        nextStep={next}
                        prevStep={prev}
                        values={values}
                        inputChange={inputChange}
                        setBirthdate={setBirthdate}
                        clearAddressProp={clearAddress}
                        selectChange={selectChange}
                        setAddressProp={setAddress}
                        setRefProp={setRef}
                        setOtpProp={setOtp}
                    />
                </>
            )
        case 4:
            return (
                <>
                    <Success
                        nextStep={next}
                        prevStep={prev}
                        values={values}
                    />
                </>
            )
        default:
            return (
                <>
                    <div key="default" className='loadingOverlay'>
                        <div className='df-center'>
                            <div className="spinLoader" />
                        </div><br />
                        {isLoading &&
                            <div className='df-center'>{loadingMsg}</div>
                        }
                    </div>
                    {(!isLoading && isExist) &&
                    
                        <Modal
                            title={<><InfoCircleFilled style={{color : '#00B19C', fontSize:'20px', marginRight : '5px'}}/>{"  "}{"แจ้งเตือน"}</> }
                            centered
                            open={isExist}
                            onOk={() => setCurrent(1)}
                            onCancel={() => setCurrent(1)}
                            // footer={null}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            width={1000}
                        >
                            <div>
                                <p>ท่านเป็นสมาชิก เรียบร้อยแล้ว</p>
                                <p>กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง</p>
                            </div>
                        </Modal>
                
                    }

                </>
            )

    }
};

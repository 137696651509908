import React, { useEffect, useState, useContext } from "react";
import {
  Flex,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Carousel,
  Typography,
  Image,
} from "antd";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import BadgeMini from "../../components/badgeMini";
import product from "../../components/product";
import Product from "../../components/product";
import { formatNumberZero } from "../../services/function";
import responseHandleSrv from "../../services/responseHandleSrv";
import { axios_json } from "../../axios";
import Api from "../../constants/api";
import { authHeader, authBasicHeader } from "../../services/auth-header";
import promotionSrv from "../../services/promotionSrv";
import productSrv from "../../services/productSrv";
import { HeaderContext } from "../../context/headerContex";

const ProductHome = () => {
  let customer =
    sessionStorage.getItem("customer_profile") === null
      ? ""
      : JSON.parse(sessionStorage.customer_profile);
  let line_profile =
    sessionStorage.getItem("line_profile") === null
      ? ""
      : JSON.parse(sessionStorage.line_profile);
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [lineProfile, setLineProfile] = useState(line_profile);
  const [loadingMsg, setLoadingMsg] = useState("กำลังตรวจสอบ Config...");
  const [data, setData] = useState(customer);
  const [promotion, setPromotion] = useState([]);
  const [products, setProduct] = useState([]);
  const { setHeaderMenu } = useContext(HeaderContext);
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  useEffect(() => {
    setHeaderMenu("promotion");
    setIsLoading(true);
    const liff = window.liff;
    // console.log(process.env.REACT_APP_LIFFID_PROMOTION);
    if (line_profile == "") {
      getData(liff, process.env.REACT_APP_LIFFID_PROMOTION);
      // getData(liff, '2002607214-XrALMEwZ');
    } else {
      if (customer == "") {
        getProfile();
      }
    }
  }, []);
  const getData = (liff, liffId) => {
    setLoadingMsg("กำลังตรวจสอบข้อมูล Line...");

    main(liff, liffId).then((profile) => {
      if (profile) {
        console.log("profile", profile);
        setLoadingMsg("กำลังตรวจสอบข้อมูลลูกค้า...");
        setLineProfile(profile);
        if (typeof Storage !== "undefined") {
          sessionStorage.line_profile = JSON.stringify(profile);
        }

        if (customer == "") {
          getCustomer(profile.userId)
            .then((response) => {
              console.log("getCustomer", response);
              if (response.data.success) {
                if (response.data.data) {
                  setData(response.data.data);
                  let res = response.data.data;
                  sessionStorage.customer_profile = JSON.stringify(res);
                  sessionStorage.is_customer = 1;
                } else {
                  sessionStorage.is_customer = 0;
                  setIsLoading(false);
                }
              }
            })
            .catch((err) => {
              setIsLoading(false);
              responseHandleSrv.handleError(err);
            });
        } else {
          setData(customer);
        }
      }
    });
  };
  const main = async (liff, liffId) => {
    await liff.init({ liffId: `${liffId}` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      return getUserProfile(liff);
    } else {
      return liff.login();
    }
  };
  const getUserProfile = async (liff) => {
    console.log("liff", liff);
    return await liff.getProfile();
  };
  const getCustomer = async (uid) => {
    return await axios_json.get(`${Api.CUSTOMER_PROFILE_BY_LINE + uid}`, {
      headers: authBasicHeader(),
    });
  };
  const getProfile = () => {
    getCustomer(lineProfile.userId)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            setData(response.data.data);
            let res = response.data.data;
            sessionStorage.customer_profile = JSON.stringify(res);
            sessionStorage.is_customer = 1;
          } else {
            sessionStorage.is_customer = 0;
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        responseHandleSrv.handleError(err);
      });
  };

  useEffect(() => {
    setPreload(true);
    getPromotion();
    getProduct();
  }, []);

  const getPromotion = () => {
    (async () => {
      await promotionSrv
        .getWithActivePeriod(data)
        .then((response) => {
          // console.log('getPromotion', response.data);
          if (response.data.success) {
            setPromotion(response.data.data);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getProduct = () => {
    let obj = {
      search: "",
      branch_id: 8,
      page: 1,
      skip: 4,
      categories: "home",
    };
    (async () => {
      await productSrv
        .getProductByBranchAndCate(obj)
        .then((response) => {
          // console.log('getProduct', response.data);
          if (response.data.success) {
            setProduct(response.data.data.result);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  // let data =
  //   sessionStorage.getItem("customer_profile") === null
  //     ? ""
  //     : JSON.parse(sessionStorage.customer_profile);
  // console.log("data", data);
  const imgCss = {
    width: "380px",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
  };
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} style={{ textAlign: "center" }}>
          <Title level={4}></Title>
        </Col>
        {/* {data != "" && (
          <Col span={22} offset={1}>
            <Card>
              <List>
                <List.Item
                  extra={<RightOutlined style={{ fontSize: "18px" }} />}
                >
                  <List.Item.Meta
                    avatar={
                      data != "" && data.picture_url ? (
                        <Avatar src={data.picture_url} />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )
                    }
                    title={
                      data != "" ? data.firstname + " " + data.lastname : ""
                    }
                    description={
                      <>
                        <Row gutter={[5, 0]}>
                          <Col>
                            <BadgeMini
                              type="stamp"
                              point={formatNumberZero(data.stamp, 0)}
                            />
                          </Col>
                          <Col>
                            <BadgeMini
                              type="point"
                              point={formatNumberZero(data.point, 0)}
                            />
                          </Col>
                          <Col>
                            <BadgeMini
                              type="coin"
                              point={formatNumberZero(data.coin, 0)}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </List.Item>
              </List>
            </Card>
          </Col>
        )} */}
        {/* <Col span={22} offset={1}>
        <Flex justify={"space-between"}>
          <div>
            <Title level={5} className={"title-h5"}>
              โปรโมชั่น
            </Title>
          </div>
          <div>
            <RightOutlined style={{ fontSize: "18px" }} />
          </div>
        </Flex>
      </Col> */}
        <Col
          span={22}
          offset={1}
          style={{
            justifyContent: "center",
            justifyItems: "center",
            textAlign: "center",
          }}
        >
          <Carousel autoplay style={{ width: "380px", margin: "0 auto" }}>
            {promotion &&
              promotion.map((pro) => (
                <div>
                  <Image src={pro.banner} style={{ ...imgCss }} />
                </div>
              ))}
          </Carousel>
        </Col>
        {/* <Col span={22} offset={1}>
        <Flex justify={"space-between"}>
          <div>
            <Title level={5} className={"title-h5"}>
              ของรางวัล
            </Title>
          </div>
          <div>
            <RightOutlined style={{ fontSize: "18px" }} />
          </div>
        </Flex>
      </Col>
      <Col span={22} offset={1}>
        <Row gutter={[5, 5]}>
          {products && products.map((p) => (
            <Col span={12}>
              <Product
                name={p.name_th}
                type={'point'}
                point={formatNumberZero(p.redeem_point)}
                img={p.thumb_pic}
              />
            </Col>
          ))}
        </Row>
      </Col> */}
      </Row>
    </>
  );
};
export default ProductHome;

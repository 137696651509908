import React, { useEffect, useState } from "react";
import { Row, Col, Flex, Button, Typography, Space, Form } from "antd";

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Input, Tooltip, Checkbox, Modal } from "antd";
import { axios_json } from "../../axios";
import { authBasicHeader } from "../../services/auth-header";
import Api from "../../constants/api";
import responseHandleSrv from "../../services/responseHandleSrv"

export default function Verify() {
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpform] = Form.useForm();
  const [counter, setCounter] = useState(300);
  const [dataOtp, setDataOtp] = useState('');
  const [checkLink, setCheckLink] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mobile_no = urlParams.get('mobileNo');
  const ref_code = urlParams.get('ref');
  const otp_code = urlParams.get('code');
  // console.log('mobile_no',mobile_no);
  const root = window.location.origin;

  // useEffect(() => {
  //   sendOtp();
  //   // console.log('send otp');
  // },[]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const Continue = (value) => {
    // e.preventDefault();
    // nextStep();
    console.log('Continue', value);
    verifyOtp(value.verify_code);
  }
  const replaceMobileNo = (mobileNo) => {
   
    let str = "XXX-XXX-" + mobileNo.slice(6, 10);
    return str;
  }
  const sendOtp = () => {
    otpform.setFieldsValue({
      'verify_code': ''
    });
    setDataOtp('');

    setCheckLink(true);
    // console.log('sendOtp', values.mobile_no);
    let data = { mobile_no: mobile_no };
    (async () => {
       await axios_json.post(`${Api.OTP_CREATE}`, data, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');
          setCounter(300);
          setDataOtp(response.data.data);
          setTimeout(() => {
            setCheckLink(false)
          }, 10000);
        }
      }).catch(err => {
        setCheckLink(true);
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const verifyOtp = (code) => {
    console.log('verifyOtp', mobile_no);
    let data = {
      mobile_no: mobile_no,
      ref_code: dataOtp != '' ? dataOtp.ref_code : ref_code,
      verify_code: code
    };
    // console.log('verifyOtp',data);
    (async () => {
      await axios_json.post(`${Api.OTP_VERIFY}`, data, {
        headers: authBasicHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          login();
        }
      }).catch(err => {
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const login = () => {
    (async () => {
      await axios_json.post(
        `/api/customer/login/otp`, {
          mobile_no: mobile_no
      }).then((response) => {
        if (response.data.success) {
          console.log('login otp',response);
          if (typeof (Storage) !== "undefined") {
            sessionStorage.user = JSON.stringify(response.data.data);
          }

          setIsLoading(false);

          window.location.href = `${root}/profile/home`;
        }
      }).catch(error => {

        setIsLoading(false);
        responseHandleSrv.handleError(error);

      });
    })();
  }

  return (
    <>
      <Form
        form={otpform}
        name="otp"
        onFinish={Continue}
      // onFinishFailed={onFinishFailed}
      >
        <Row justify="center" gutter={[0, 10]}>
          <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
            <Title level={5}>ยืนยัน SMS OTP</Title>
            <p>
              <Text type="secondary">
                รหัสยืนยันได้ถูกส่งไปยังเบอรโทศัพท์  {replaceMobileNo(mobile_no)}
              </Text>
            </p>
            <p>
              <Text type="secondary">รหัสจะมีอายุ 5 นาที (รหัสอ้างอิง: {dataOtp != '' ? dataOtp.ref_code : ref_code})</Text>

            </p>
            <p>
              <Text type="secondary">รหัสยืนยัน: {dataOtp != '' ? dataOtp.verify_code : otp_code}</Text>
            </p>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 10]}>
          <Col span={22}>
            <div>
              <Form.Item
                name="verify_code"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัส OTP</label>}
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัส OTP'

                  }
                ]}
              >
                <Input placeholder="รหัส OTP" maxLength={4} />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} style={{ textAlign: "center" }}>
            {/* <Button type="primary" block onClick={Continue}>
              ยืนยัน
            </Button> */}
            <Form.Item
            >
              <Button type='primary' htmlType="submit" block>ยืนยัน</Button>
            </Form.Item>
          </Col>
          <Col span={22} style={{ textAlign: "center" }}>
            {/* <Text type="secondary">ขอรหัส OTP (59 วินาที)</Text> */}
            <a className='df-centerY' style={{ marginBottom: '25px', color : '#1ABC9C' }}
              disabled={checkLink === true ? true : false} onClick={sendOtp} >ขอรหัส OTP อีกครั้ง </a> ({counter} วินาที)

          </Col>
        </Row>
      </Form>
    </>
  );
}

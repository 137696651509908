import React, { useContext } from "react";
import { Spin, Flex } from "antd";
import { PreloadContext } from "../context/preload";
export default function Preload({ children }) {
  const { loading } = useContext(PreloadContext);
  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" style={{ height: "50vh" }}>
          <Spin size={"large"} />
        </Flex>
      ) : (
        children
      )}
    </>
  );
}

import React, { createContext, useState } from "react";
const HeaderContext = createContext({});
const HeaderContextProvider = ({ children }) => {
  const [headerMenu, setHeaderMenu] = useState("");
  return (
    <HeaderContext.Provider value={{headerMenu,setHeaderMenu}}>
      {children}
    </HeaderContext.Provider>
  );
};
export { HeaderContext, HeaderContextProvider };

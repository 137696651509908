import React, { useState, useEffect } from "react";
import { Row, Col, Button, Typography, Form } from "antd";
// import moment from 'moment';
// import { AiOutlineSync, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import StepPage from './stepPage';
import { Input} from "antd";
import { axios_json } from "../../axios";
import { authBasicHeader } from "../../services/auth-header";
import Api from "../../constants/api";
import responseHandleSrv from "../../services/responseHandleSrv"


const StepOTP = ({ nextStep, prevStep, values, setRefProp, setOtpProp}) => {

  const { Title, Text } = Typography;
  const [checkLink, setCheckLink] = useState(false);
  const [dataOtp, setDataOtp] = useState('');
  const [tick, setTick] = useState(0);
  const [counter, setCounter] = useState(values.countTime);

  const [otpform] = Form.useForm();
  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  // if (counter == 0) {
  //   setTick(tick + 1);
    // setTimeout(() => {
    //   setCounter(30);
    // }, 3000);
  // }
  return () => clearInterval(timer);
  }, [counter]);
  const Continue = (value) => {
    // e.preventDefault();
    // nextStep();
    console.log('Continue',value);
    verifyOtp(value.verify_code);
  }
  const replaceMobileNo = (mobileNo) => {
    let str = "XXX-XXX-" + mobileNo.slice(6, 10);
    return str;
  }
  const sendOtp = () => {
    otpform.setFieldsValue({
      'verify_code': ''
    });
    setDataOtp('');
    
    setCheckLink(true);
    // console.log('sendOtp', values.mobile_no);
    let data = { mobile_no: values.mobile_no };
      (async () => {
        await axios_json.post(`${Api.OTP_CREATE}`, data, {
          headers: authBasicHeader()
        }).then(response => {
          // console.log('response', response);
          if (response.data.success) {
            responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');
            setCounter(values.countTime);
            setDataOtp(response.data.data);
            setRefProp(response.data.data.ref_code);
            setOtpProp(response.data.data.verify_code);
            setTimeout(() => {
              setCheckLink(false)
          }, 10000);
          }
        }).catch(err => {
          setCheckLink(true);
          responseHandleSrv.handleError(err);
        })
      })();
  };
  const verifyOtp = (code) => {
    console.log('verifyOtp', values.mobile_no);
    let data =  {
      mobile_no: values.mobile_no,
      ref_code: values.ref_code,
      verify_code: code
    };
      (async () => {
        await axios_json.post(`${Api.OTP_VERIFY}`, data, {
          headers: authBasicHeader()
        }).then(response => {
          console.log('response', response);
          if (response.data.success) {
            nextStep();
          }
        }).catch(err => {
          responseHandleSrv.handleError(err);
        })
      })();
  };

  return (
    <>
      <StepPage step={1} />
      <Form
        form={otpform}
        name="otp"
        onFinish={Continue}
      // onFinishFailed={onFinishFailed}
      >
        <Row justify="center" gutter={[0, 10]}>
          <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
            <Title level={5}>ยืนยัน SMS OTP</Title>
            <p>
              <Text type="secondary">
                รหัสยืนยันได้ถูกส่งไปยังเบอรโทศัพท์  {replaceMobileNo(values.mobile_no)}
              </Text>
            </p>
            <p>
              <Text type="secondary">รหัสจะมีอายุ 5 นาที (รหัสอ้างอิง: {values.ref_code})</Text>
             
            </p>
            <p>
              <Text type="secondary">รหัสยืนยัน: {values.otp_code}</Text>
            </p>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 10]}>
          <Col span={22}>
            <div>
              <Form.Item
                name="verify_code"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัส OTP</label>}
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัส OTP'

                  }
                ]}
              >
                <Input placeholder="รหัส OTP" maxLength={4}/>
              </Form.Item>
            </div>
          </Col>

          <Col span={22} style={{ textAlign: "center" }}>
            {/* <Button type="primary" block onClick={Continue}>
              ยืนยัน
            </Button> */}
            <Form.Item
            >
              <Button type='primary' htmlType="submit" block>ยืนยัน</Button>
            </Form.Item>
          </Col>
          <Col span={22} style={{ textAlign: "center" }}>
            {/* <Text type="secondary">ขอรหัส OTP (59 วินาที)</Text> */}
            <a className='df-centerY' style={{ marginBottom: '25px' }} 
            disabled={checkLink === true ? true : false} onClick={sendOtp} >ขอรหัส OTP อีกครั้ง </a> ({counter} วินาที)

          </Col>
        </Row>
      </Form>
    </>
  );
}


export default StepOTP

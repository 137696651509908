import React from "react";
import { Card, Row, Col, Typography, Image } from "antd";
import stamp from "../assets/images/stamp.png";
import point from "../assets/images/point.png";
import coin from "../assets/images/coin.png";
import ico_coin from "../assets/images/ico-coin.svg";

export default function HistoryCard(props) {
  console.log('props', props);
  const { Text, Title } = Typography;
  let imgSrc = stamp;
  let score_type = "";
  const type = props.type;

  switch (type) {
    case "stamp":
      imgSrc = stamp;
      score_type = "E-Stamp";
      break;
    case "point":
      imgSrc = point;
      score_type = "Point";
      break;
    case "coin":
      imgSrc = coin;
      score_type = "Joylicoin";
      break;
    default:
      imgSrc = stamp;
      score_type = "E-Stamp";
      break;
  }
  // let pointLabel = props.point > 0 ? "+" + props.point : props.point;
  let pointLabel = props.point.includes("-") ? props.point : "+" + props.point;
  let styleCSS =
    props.today === 0
      ? {
        card: {
          padding: "0px",
          filter: "grayscale(1)",
          backgroundColor: "#DFE0E1",
        },
      }
      : {
        card: {
          padding: "0px",
        },
      };

  return (
    <>
      <Card bodyStyle={styleCSS.card}>
        <Row type="flex" style={{ alignItems: "center" }}>
          <Col span={6}>
            {props.thumb !== "" ?
              <Image
                src={props.thumb}
                height={80}
              />
              :
              <Image
                src={"https://echo-api.nirun.tech/images/noimage.png"}
                height={80}
              />
            }
          </Col>
          <Col span={12}>
            {/* <Title level={5} style={{ marginTop: "5px" }}>
              {props.product ? props.product : ''}
            </Title> */}
            <div style={{ fontWeight: "bold", fontSize: "14px",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{props.product ? props.product : ''}</div>
            {/* {props.product ?
             <Text type="secordary">คุณแลกใช้ {score_type} <br/>{props.dateat} <br/>สาขา {props.branch}</Text>
            :
            <Text type="secordary">คุณได้รับ {score_type} <br/>{props.dateat} <br/>สาขา {props.branch}</Text>
            } */}
        
              <div>{props.product ? "คุณแลกใช้" : "คุณได้รับ"} {score_type}</div>
              <div>{props.dateat}</div>
              <div>สาขา {props.branch}</div>

            {/* <Text type="secordary">{props.dateat}</Text>
            <Text type="secordary">สาขา {props.branch}</Text> */}
          </Col>
          <Col span={6}>
            <Title level={4} style={{ marginTop: "0px" }}>
              {pointLabel}
              <Image
                src={ico_coin}
                preview={false}
                style={{
                  marginLeft: "4px",
                  top: "-5px",
                  position: "relative",
                }}
                height={25}
              />
            </Title>
          </Col>
        </Row>
      </Card>
    </>
  );
}

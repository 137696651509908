import React, { useEffect, useState } from "react";
import HistoryCard from "./historyCard";
import { Space, Avatar, Button, List, Skeleton, Typography } from "antd";
import moment from "moment";
import { formatNumberZero } from "../services/function";
import { authBasicHeader } from "../services/auth-header";
import responseHandleSrv from "../services/responseHandleSrv";
import { axios_json } from "../axios";
import Api from "../constants/api";
export default function HistoryTabs(props) {
  let customer =
    sessionStorage.getItem("customer_profile") === null
      ? ""
      : JSON.parse(sessionStorage.customer_profile);

  const { tab, start } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [lists, setList] = useState([]);
  const [data, setData] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(start);
  const [total, setTotal] = useState(false);
  const [indexToday, setIndexToday] = useState(0);

  const [pageData, setPageData] = useState({
    currentPage: start,
    pageSize: 5,
    totalPage: "",
    previousPage: "",
    nextPage: "",
    totalcount: "",
    goToPage: "",
  });
  const { Title, Text } = Typography;
  useEffect(() => {
    // setIsLoading(true);
    // setList([]);
    // console.log('tabHistory', tab);
    // console.log('start', start);
    setIndexToday(0);
    switch (tab) {
      case "2":
        getEarnList(pageData.currentPage, pageData.pageSize, "");
        break;
      case "3":
        getRedeemList(pageData.currentPage, pageData.pageSize, "");
        break;
      default:
        getList(pageData.currentPage, pageData.pageSize, "");
    }
  }, []);
const calDate = (ls) => {
  let dd = moment().locale('th').format("DD/MM/YYYY");
  let dateFilter = ls.filter(d => moment(d.transaction_date).utc().format("DD/MM/YYYY") === dd);
  console.log('today',dd);
  console.log('dateFilter',dateFilter.length);
  let count = dateFilter.length;
  let ss = indexToday + count;
  setIndexToday(ss);
};
  const getList = (page, pageSize, search) => {
    // console.log('page', page);
    (async () => {
      setIsLoading(true);
      await axios_json
        .get(`${Api.SCORE_HISTORY_LIST}`, {
          headers: authBasicHeader(),
          params: {
            cust_id: customer.id,
            score_type_id: null,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          console.log("getList", response);
          if (response.data.success) {
            let data = response.data.data.result;

            if (lists.length === 0) {
              calDate(data);
              setList(data);
            } else {
              calDate(data);
              let exist = lists.concat(data);
              setList(exist);
            }
            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
          }
          setIsLoading(false);
          setInitLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setInitLoading(false);

          responseHandleSrv.handleError(err);
        });
    })();
  };
  const getEarnList = (page, pageSize, search) => {
    (async () => {
      setIsLoading(true);
      await axios_json
        .get(`${Api.SCORE_HISTORY_ERAN_LIST}`, {
          headers: authBasicHeader(),
          params: {
            cust_id: customer.id,
            score_type_id: null,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          console.log("getEarnList", response);
          if (response.data.success) {
            let data = response.data.data.result;
            if (lists.length === 0) {
              calDate(data);
              setList(data);
            } else {
              calDate(data);
              let exist = lists.concat(data);
              setList(exist);
            }
            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const getRedeemList = (page, pageSize, search) => {
    (async () => {
      setIsLoading(true);
      await axios_json
        .get(`${Api.SCORE_HISTORY_REDEEM_LIST}`, {
          headers: authBasicHeader(),
          params: {
            cust_id: customer.id,
            score_type_id: null,
            page,
            skip: pageSize,
          },
        })
        .then((response) => {
          // console.log("getRedeemList", response);
          if (response.data.success) {
            let data = response.data.data.result;
            if (lists.length === 0) {
              calDate(data);
              setList(data);
            } else {
              calDate(data);
              let exist = lists.concat(data);
              setList(exist);
            }
            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount,
            }));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onLoadMore = () => {
    setLoading(true);

    let current = pageData.currentPage;
    // console.log('current', current);
    setPageData((val) => ({
      ...val,
      currentPage: current + 1,
    }));
    let next = current + 1;
    switch (tab) {
      case "2":
        getEarnList(next, pageData.pageSize, "");
        break;
      case "3":
        getRedeemList(next, pageData.pageSize, "");
        break;
      default:
        getList(next, pageData.pageSize, "");
    }
  };
  const loadMore =
    pageData.totalcount !== lists.length && !isLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>more</Button>
      </div>
    ) : null;

  return (
    <Space size="small" direction="vertical" style={{ display: "flex" }}>
      <List
        loading={isLoading}
        itemLayout="vertical"
        loadMore={loadMore}
        dataSource={lists}
        renderItem={(s, i) => (
          <List.Item
            style={{ border: "0px solid" }}
            // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
          >
            {(i === 0 && indexToday > 0) && (
              <List.Item.Meta title={<Title level={3}>วันนี้</Title>} />
            )}
             {(i === indexToday) && (
              <List.Item.Meta title={<Title level={3}>เมื่อวาน</Title>} />
            )}
            <HistoryCard
              type={s.score_type_name.toLowerCase()}
              point={formatNumberZero(s.amount, 0)}
              dateat={moment(s.transaction_date)
                .utc()
                .format("DD/MM/YYYY HH:mm")}
              branch={s.branch_name_th}
              desc={s.description}
              thumb={s.thumb_pic}
              product={s.product_name_th}
              // today={i == 0 ? 1 : 0}
              today={moment(s.transaction_date).utc().format("DD/MM/YYYY") === moment().locale('th').format("DD/MM/YYYY") ? 1 : 0}
            />
          </List.Item>
        )}
      ></List>
    </Space>
  );
}

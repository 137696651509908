import React, { useState,useContext ,useEffect} from "react";
import { Row, Col, Flex, Button, Typography, Form } from "antd";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Input, Tooltip, Checkbox, Modal } from "antd";
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from "../../services/auth-header";
import { HeaderContext } from "../../context/headerContex";

export default function Signin() {
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const root = window.location.origin;
  const { setHeaderMenu } = useContext(HeaderContext);

  let line_profile = sessionStorage.getItem('line_profile') === null ? '' : JSON.parse(sessionStorage.line_profile);

  useEffect(() => {
    setHeaderMenu("signin");
  }, []);

  const onFinish = (values) => {
    console.log(values);
    if (values.username && values.password) {
      (async () => {
        await axios_json.post(
          `/api/customer/login`, {
          username: values.username,
          password: values.password
        }).then((response) => {
          if (response.data.success) {
            console.log('login', response);
            if (typeof (Storage) !== "undefined") {
              sessionStorage.user = JSON.stringify(response.data.data);
            }

            setIsLoading(false);

            window.location.href = `${root}/profile/home`;
          }
        }).catch(error => {

          setIsLoading(false);
          responseHandleSrv.handleError(error);

        });
      })();
    }

  };
  const onFinishFailed = () => { };


  return (
    <>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
          <Title level={5}>เข้าสู่ระบบ</Title>
        </Col>
      </Row>
      <Form
        form={form}
        name="user"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify="center" gutter={[0, 10]}>
          <Col span={22} >
            <div>
              <Form.Item
                name="username"
                label={
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    ชื่อผู้ใช้
                  </label>
                }
                rules={[
                  {
                    required: true,
                    message: "ชื่อผู้ใช้",
                  },
                  // {
                  //   pattern: /^[0][0-9]{9}$\b/,
                  //   message: "เบอร์โทรศัพท์ไม่ถูกต้อง",
                  // },
                ]}
              >
                <Input
                  placeholder="Enter your username"
                  maxLength={10}
                />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} >
            <div>
              <Form.Item
                name="password"
                label={<label style={{ fontWeight: "bold", fontSize: "14px" }}>รหัสผ่าน</label>}
                rules={[
                  {
                    required: true,
                    message: 'รหัสผ่าน'

                  }
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
            <div>
              <div>
                <Link to="/forgotpwd" style={{ color: '#7745FF' }}>
                  ลืมรหัสผ่าน
                </Link>&nbsp;&nbsp;
              </div>
            </div>
          </Col>


          <Col span={22} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Col>
          <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
            {line_profile != '' ? 
            <Link to="/line/register" style={{ color: '#7745FF' }}>ลงทะเบียน</Link>
                : <Link to="/register/home" style={{ color: '#7745FF' }}>ลงทะเบียน</Link>
            }
            
          </Col>
        </Row>
      </Form >

    </>
  );
}

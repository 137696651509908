import React, { useState } from "react";
import { Row, Col, Flex, Button, Typography } from "antd";

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Input, Tooltip, Checkbox, Modal } from "antd";

export default function Resetpassword() {
  const { Title,Text } = Typography;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Row justify="center" gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
                  <Title level={5}>รีเซ็ตรหัสผ่าน</Title>
                 <p><Text type="secondary">โปรดระบุรหัสผ่านใหม่</Text></p> 
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 10]}>
      
        <Col span={22} >
          <div>
            <Typography.Title level={5} className="title-h5">รหัสผ่าน</Typography.Title>
            <Input.Password placeholder="Password" />
          </div>
        </Col>

        <Col span={22} >
          <div>
            <Typography.Title level={5} className="title-h5">ยืนยันรหัสผ่าน</Typography.Title>
            <Input.Password placeholder="Confirm password" />
          </div>
        </Col>
       
        <Col span={22} style={{ textAlign: "center" }}>
          <Button type="primary" block>
            ยืนยัน
          </Button>
        </Col>
      </Row>

    </>
  );
}

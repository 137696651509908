import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography } from "antd";
import axios from "axios";
import Preload from "../components/preload";
import { PreloadContext } from "../context/preload";
const Consent = () => {
  const { Title } = Typography;
  const [consent, setConsent] = useState();
  const { setLoading } = useContext(PreloadContext);
  const headers = {
    Authorization: "Basic " + process.env.REACT_APP_BASIC_HEADER,
    accept: "application/json",
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          process.env.REACT_APP_API_PRD + "/api/content/consent",
          { headers }
        );
        setConsent(data.data.data.description);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={22} offset={1} style={{ textAlign: "center" }}>
          <Title level={4}>Consent</Title>
        </Col>
      </Row>
      <Preload>
        <Row gutter={[10, 10]}>
          <Col span={22} pull={1} offset={2}>
            <div dangerouslySetInnerHTML={{ __html: consent }} />
          </Col>
        </Row>
      </Preload>
    </>
  );
};
export default Consent;

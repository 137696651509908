import React from "react";
import { Card, Row, Col, Divider, Typography, Flex, Image, Tag } from "antd";
import { RightOutlined } from "@ant-design/icons";
import stamp from "../assets/images/stamp.png";
import point from "../assets/images/point.png";
import coin from "../assets/images/coin.png";
import ico_coin from "../assets/images/ico-coin.svg";
import BadgeMini from "./badgeMini";
export default function Product(props) {
  let imgSrc = stamp;
  const type = props.type;
  const { Title, Text } = Typography;
  switch (type) {
    case "stamp":
      imgSrc = stamp;
      break;
    case "point":
      imgSrc = point;
      break;
    case "coin":
      imgSrc = coin;
      break;
    default:
      imgSrc = stamp;
      break;
  }

  return (
    <>
      <Card style={{ height: "136px" }} bodyStyle={{padding:"0px"}}>
        <Row gutter={[0, 0]} style={{ backgroundColor: "#DFE0E1" }}>
          <Col span={14} style={{paddingLeft:"10px"}}>
            <Title level={5}>{props.name}</Title>
            <Tag color="#FFF" style={{ minWidth: "65px", padding: "2px 8px" }}>
              <Flex justify="center" align="center">
              <Title level={5} style={{ color: "#000" ,marginTop:"0px",marginBottom:"0px"}}>
                {props.point}    
                </Title>
                <Image src={ico_coin} preview={false} style={{marginLeft:"4px"}} />
                </Flex>
            </Tag>
          </Col>
          <Col span={10} style={{textAlign:"right"}}>
            <Image src={props.img} height={136} />
          </Col>
        </Row>
      </Card>
    </>
  );
}
